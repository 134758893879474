import axios from "axios";
import { Action, ActionCreator, Reducer } from "redux";
import { ThunkAction } from "redux-thunk";

import settings from "@settings";
import type { ISwimlane } from "./types";
import type { IAPIResponse } from "@lib/api";

// Action types
const GET_SWIMLANES_CONFIG = "swimlanes/GET_SWIMLANES_CONFIG";
const GET_SWIMLANES_CONFIG_ERROR = "swimlanes/GET_SWIMLANES_CONFIG_ERROR";
const GET_SWIMLANES_CONFIG_SUCCESS = "swimlanes/GET_SWIMLANES_CONFIG_SUCCESS";

// Action interfaces
interface ISwimlanesConfigRequest extends Action<typeof GET_SWIMLANES_CONFIG> {}
interface ISwimlanesConfigError extends Action<typeof GET_SWIMLANES_CONFIG_ERROR> {}
interface ISwimlanesConfigSuccess extends Action<typeof GET_SWIMLANES_CONFIG_SUCCESS> {
  payload: ISwimlane[];
}

type SwimlanesActionType = ISwimlanesConfigRequest | ISwimlanesConfigError | ISwimlanesConfigSuccess;

interface ISwimlanesState {
  loading: boolean;
  error: boolean;
  success: boolean;
  swimlanes: ISwimlane[];
}

// Initial state
const initialState: ISwimlanesState = {
  loading: false,
  error: false,
  success: false,
  swimlanes: [],
};

// Reducer
const swimlanesReducer: Reducer<ISwimlanesState, SwimlanesActionType> = (state = initialState, action) => {
  switch (action.type) {
    case GET_SWIMLANES_CONFIG:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case GET_SWIMLANES_CONFIG_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
    case GET_SWIMLANES_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        swimlanes: action.payload,
      };
    default:
      return state;
  }
};
export default swimlanesReducer;

// Actions
export const getSwimlanesConfig: ActionCreator<
  ThunkAction<void, ISwimlanesState, void, ISwimlanesConfigRequest | ISwimlanesConfigError | ISwimlanesConfigSuccess>
> = () => async (dispatch) => {
  dispatch({ type: GET_SWIMLANES_CONFIG });
  try {
    const apiResponse = await axios.get<IAPIResponse<ISwimlane[]>>(settings.contentHub.swimlanes);
    dispatch({ type: GET_SWIMLANES_CONFIG_SUCCESS, payload: apiResponse.data.response });
  } catch (e) {
    dispatch({ type: GET_SWIMLANES_CONFIG_ERROR });
  }
};
