export enum FetchStatus {
  NotStarted,
  Loading,
  Error,
  Success,
}

export interface IDictionary<T> {
  [key: string]: T;
}

export interface ICampaignBanner {
  adDesktopImage: string;
  adLink: string;
  adMobileImage: string;
  adPosition: string;
  adText: string;
  adType: string;
}

export interface IChannel {
  id: number;
  title: string;
  description: string;
  isHd: boolean;
  stbNumber: string;
  language: string;
  category: string;
  imageUrl: string;
  isAstroGoExclusive: boolean;
  filters: string[];
  campaignBanner: ICampaignBanner;
  isOnDemandExclusive: string;
  isRadioExclusive: string;
}

export interface ICurrentSchedule {
  eventId: string;
  title: string;
  programmeId: string;
  episodeId: string;
  datetime: string;
  datetimeInUtc: string;
  duration: string;
  siTrafficKey: string;
  detailUrl: string;
}

export enum FILTERTYPE {
  channelNumber = "channelNumber",
  channelLanguages = "channelLanguages",
  channelResolution = "channelResolution",
  channelCategories = "channelCategories",
  firstGenres = "firstGenres",
  firstLanguages = "firstLanguages",
  bestGenres = "bestGenres",
  bestLanguages = "bestLanguages",
  movieGenres = "movieGenres",
  movieLanguages = "movieLanguages",
  showGenres = "showGenres",
  showLanguages = "showLanguages",
  kidGenres = "kidGenres",
  kidLanguages = "kidLanguages",
}

export enum RESOLUTION {
  uhd,
  hd,
  sd,
}
