import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";

import App from "@app";
import configureStore from "@store";

import "./style.css";

const store = configureStore();
const container = document.getElementById("dWx0cmEtY29udGVudC1odWI");

if (container) {
  ReactDOM.createRoot(container).render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}
