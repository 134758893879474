import axios from "axios";
import { Action, ActionCreator, Reducer } from "redux";
import { ThunkAction } from "redux-thunk";
import settings from "@settings";
import { IFortressResponse, extractConfig } from "@lib/fortress";
import type { IDictionary } from "@ducks/types";
import {
  IAppConfig,
  IListingConfig,
  IDetailsConfig,
  ILanguages,
  ILanguage,
  IHowToGuideConfig,
  IMetaConfig,
  IListingCategoryMeta,
  IFilterSidebarConfig,
  IFilterGroup,
  IResizerConfig,
  IFilterConfig,
  IChannelDefaultImages,
} from "./types";
import * as constants from "./constants";
import type { AppState } from "@store";

// Action Types
const GET_CONFIG = "config/GET_CONFIG";
const GET_CONFIG_ERROR = "config/GET_CONFIG_ERROR";
const GET_CONFIG_SUCCESS = "config/CONFIG_LOADED";

// Action interface
interface IConfigRequest extends Action<typeof GET_CONFIG> {}
interface IConfigError extends Action<typeof GET_CONFIG_ERROR> {}
interface IConfigSuccess extends Action<typeof GET_CONFIG_SUCCESS> {
  payload: IConfigPayload;
}
interface IConfigPayload {
  app?: IAppConfig;
  listing?: IListingConfig;
  details?: IDetailsConfig;
  filterSidebar?: IFilterSidebarConfig;
  howToGuide?: IHowToGuideConfig;
  language?: ILanguage;
  whatsapp?: IDictionary<string>;
  meta?: IMetaConfig;
  resizer?: IResizerConfig;
  filterConfig?: IFilterConfig;
  channelDefaultImages?: IChannelDefaultImages;
}

type ConfigActionTypes = IConfigRequest | IConfigError | IConfigSuccess;

interface IConfigState {
  loading: boolean;
  error: boolean;
  success: boolean;
  app: IAppConfig;
  listing: IListingConfig;
  details: IDetailsConfig;
  filterSidebar: IFilterSidebarConfig;
  howToGuide: IHowToGuideConfig;
  whatsapp: IDictionary<string>;
  language: ILanguage;
  meta: IMetaConfig;
  resizer: IResizerConfig;
  filterConfig: IFilterConfig;
  channelDefaultImages: IChannelDefaultImages;
}

// Initial state
const emptyListTypeMap = {
  first: "",
  best: "",
  movie: "",
  show: "",
  kid: "",
  channels: "",
};

const emptyListingMeta: IListingCategoryMeta = {
  title: "",
  description: "",
  keywords: "",
};

const emptyFilterGroup: IFilterGroup = {
  header: "",
  filters: [],
};

const initialState: IConfigState = {
  loading: false,
  error: false,
  success: false,
  app: {
    routes: constants.ROUTES,
    breadcrumbsSeparator: constants.BREADCRUMBS_SEPARATOR,
    breadcrumbsDefaultCategory: constants.BREADCRUMBS_DEFAULT_CATEGORY,
    breadcrumbsCategoryMap: {},
    showtimesTimezone: constants.SHOWTIMES_TIMEZONE,
  },
  listing: {
    showBanner: constants.SHOW_BANNER,
    bannerDMConfig: constants.BANNER_DM_CONFIG,
    bannerAutoplayDuration: constants.BANNER_AUTOPLAY_DURATION,
    defaultBannerBackground: constants.DEFAULT_BANNER_BACKGROUND,
    defaultListType: constants.DEFAULT_LIST_TYPE,
    listTypes: [],
    cardColumns: constants.LISTING_CARD_COLUMNS,
    posterColumns: constants.LISTING_POSTER_COLUMNS,
    channelColumns: constants.LISTING_CHANNEL_COLUMNS,
    maxChannelSchedule: constants.MAX_CHANNEL_SCHEDULE,
    channelCategories: [],
    swimlaneColumns: {
      card: constants.SWIMLANE_CARD_COLUMNS,
      poster: constants.SWIMLANE_POSTER_COLUMNS,
    },
    swiperConfig: {
      breakpoints: {
        lgDevice: {},
        smDevice: {},
      },
    },
  },
  details: {
    icons: constants.DETAILS_ICONS,
    desktopInfo: [],
    mobileInfo: [],
    maxCastNumber: constants.DETAILS_MAX_CAST_NUMBER,
    howToGuideLink: "",
    whatsappIcon: "",
    whatsappUrl: constants.WHATSAPP_URL,
    whatsappPhoneNumber: constants.WHATSAPP_PHONE_NUMBER,
    maxLinearShowtimes: constants.DETAILS_MAX_LINEAR_SHOWTIMES,
    maxLinearEpisodes: constants.DETAILS_MAX_LINEAR_EPISODES,
  },
  filterSidebar: {
    groups: {},
  },
  howToGuide: {
    showBanner: constants.HOW_TO_GUIDE_SHOW_BANNER,
    banner: "",
  },
  whatsapp: {},
  language: {
    CONTENT: "Content",
    MORE: "",
    GENERIC_ERROR_TITLE: "",
    GENERIC_ERROR_MESSAGE: "",
    PAGE_NOT_FOUND_TITLE: "",
    PAGE_NOT_FOUND_MESSAGE: "",
    NO_CHANNELS_FOUND: "",
    GO_TO_HOME: "",
    BACK_TO_CONTENT_HUB: "",
    CHANNEL_PREFIX: "",
    VIEW_ON_CHANNEL: "",
    CATEGORY: "",
    listing: {
      TITLE: "",
      WATCH_TRAILER: "",
      AVAILABLE_ON_DEMAND: "",
      LOADING: "",
      NO_SHOW_FOUND: "",
      NO_ITEMS_FOUND: "",
      HOMEPAGE: "",
      SEARCH: "",
      SEARCH_RESULT: "",
      NUMBER_OF_RESULTS_AMOUNT: "",
      NUMBER_OF_RESULTS_QUERY: "",
      INVALID_SEARCH: "",
      tabs: emptyListTypeMap,
      headers: emptyListTypeMap,
      INVALID_SEARCH_INPUT: "",
      COMING_SOON: "",
      EARLY_BIRD_PROMO: "",
      COMING_TO_YOU: "",
      ALL_MOVIES: "",
    },
    details: {
      OR: "",
      NOW: "",
      CH: "",
      SHOW_EXPIRED: "",
      DURATION: "",
      ON_DEMAND: "",
      ASTRO_GO: "",
      HOW_TO_GUIDE: "",
      WIFI_TO_CONNECT: "",
      WHATSAPP: "",
      PRICE: "",
      PRICE_FOR_DAYS: "",
      AFAB_PRICE_FOR_DAYS: "",
      PURCHASE_VIA_WHATSAPP: "",
      SHOWTIMES: "",
      TODAY: "",
      TOMORROW: "",
      ON_NOW: "",
      SHOWTIMES_NO_INFO: "",
      MORE_EPISODES: "",
      BACK_TO_CONTENT_HUB: "",
      attributes: {},
      UPSELL_EXPLORE: "",
      CHOOSE_YOUR_PACK: "",
      EARLY_BIRD_PROMO: "",
      COMING_SOON: "",
      SHOWING_ON: "",
    },
    channels: {
      ASTRO_GO_EXCLUSIVE: "",
      ON_DEMAND_CHANNELS: "",
      ON_DEMAND_EXCLUSIVE: "",
      ON_NOW: "",
      NA: "",
      NO_INFORMATION_AVAILABLE: "",
      TODAY: "",
    },
    filter: {
      CHANNEL_FILTER: "",
      FILTER: "",
      RESET: "",
      APPLY: "",
    },
    howToGuide: {
      TITLE: "",
      INSTRUCTION: "",
    },
    filterMap: {},
    languageMap: {},
    channelMap: {},
    purchaseMap: {},
  },
  meta: {
    titleTemplate: constants.META_TITLE_TEMPLATE,
    thumbnail: constants.META_THUMBNAIL,
    ogType: constants.META_OG_TYPE,
    twitterCard: constants.META_TWITTER_CARD,
    twitterSite: constants.META_TWITTER_SITE,
    listing: {
      categories: {
        mustwatch: emptyListingMeta,
        first: emptyListingMeta,
        best: emptyListingMeta,
        movie: emptyListingMeta,
        show: emptyListingMeta,
        kid: emptyListingMeta,
        channels: emptyListingMeta,
        homepage: emptyListingMeta,
        howtoguide: emptyListingMeta,
      },
      bannerAlt: "",
      productAlt: "",
    },
    details: {
      titleTemplate: "",
      title: "",
      channelDescription: "",
      castDescription: "",
      keywords: "",
      bannerAlt: "",
      productAlt: "",
    },
    channel: {
      titleTemplate: "",
      title: "",
      description: "",
    },
    search: {
      title: "",
      description: "",
      keywords: "",
    },
  },
  resizer: {
    url: "https://resizer.eco.astro.com.my",
    config: {
      default: "fetch/f_auto,q_auto",
      detail: "fetch/f_auto,q_auto,w_768",
      card_portrait: "fetch/f_auto,q_auto,w_249",
      card_landscape: "fetch/f_auto,q_auto,w_368",
    },
  },
  filterConfig: {
    filter: {
      groups: {
        channelNumber: emptyFilterGroup,
        channelCategories: emptyFilterGroup,
        channelLanguages: emptyFilterGroup,
        channelResolution: emptyFilterGroup,
        firstGenres: emptyFilterGroup,
        firstLanguages: emptyFilterGroup,
        bestGenres: emptyFilterGroup,
        bestLanguages: emptyFilterGroup,
        movieGenres: emptyFilterGroup,
        movieLanguages: emptyFilterGroup,
        showGenres: emptyFilterGroup,
        showLanguages: emptyFilterGroup,
        kidGenres: emptyFilterGroup,
        kidLanguages: emptyFilterGroup,
      },
      combinedLanguageMap: {
        malay: [],
        chinese: [],
        english: [],
        indian: [],
        korean: [],
      },
    },
    filterMap: {},
  },
  channelDefaultImages: {},
};

// Reducer
const configReducer: Reducer<IConfigState, ConfigActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIG:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case GET_CONFIG_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default configReducer;

// Actions
export const getConfig: ActionCreator<ThunkAction<void, AppState, undefined, ConfigActionTypes>> =
  () => async (dispatch) => {
    dispatch({ type: GET_CONFIG });
    try {
      const response = await axios.get<IFortressResponse<any>>(settings.config.url); // eslint-disable-line @typescript-eslint/no-explicit-any
      const { data } = response;
      const payload: IConfigPayload = {};

      const appConfig = extractConfig<IAppConfig>(settings.config.key.app, data);
      if (appConfig) payload.app = appConfig;

      const listingConfig = extractConfig<IListingConfig>(settings.config.key.listing, data);
      if (listingConfig) payload.listing = listingConfig;

      const detailsConfig = extractConfig<IDetailsConfig>(settings.config.key.details, data);
      if (detailsConfig) payload.details = detailsConfig;

      const filterSidebarConfig = extractConfig<IFilterSidebarConfig>(settings.config.key.filterSidebar, data);
      if (filterSidebarConfig) payload.filterSidebar = filterSidebarConfig;

      const howToGuideConfig = extractConfig<IHowToGuideConfig>(settings.config.key.howToGuide, data);
      if (howToGuideConfig) payload.howToGuide = howToGuideConfig;

      const whatsappCodes = extractConfig<IDictionary<string>>(settings.config.key.whatsapp, data);
      if (whatsappCodes) payload.whatsapp = whatsappCodes;

      const languageConfig = extractConfig<ILanguages>(settings.config.key.language, data);
      if (languageConfig) if (languageConfig.en) payload.language = languageConfig.en;

      const metaConfig = extractConfig<IMetaConfig>(settings.config.key.meta, data);
      if (metaConfig) payload.meta = metaConfig;

      const resizerConfig = extractConfig<IResizerConfig>(settings.config.key.resizer, data);
      if (resizerConfig) payload.resizer = resizerConfig;

      const filterConfig = extractConfig<IFilterConfig>(settings.config.key.filterConfig, data);
      if (filterConfig) payload.filterConfig = filterConfig;

      const channelDefaultImages = extractConfig<IChannelDefaultImages>(settings.config.key.channelDefaultImages, data);
      if (channelDefaultImages) payload.channelDefaultImages = channelDefaultImages;

      dispatch({ type: GET_CONFIG_SUCCESS, payload });
    } catch (e) {
      dispatch({ type: GET_CONFIG_ERROR });
    }
  };
