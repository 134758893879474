import axios from "axios";
import { Action, ActionCreator, Reducer } from "redux";
import { ThunkAction } from "redux-thunk";

import settings from "@settings";
import { IAPIResponse } from "@lib/api";
import type { IGuide } from "./types";

// Action types
const GET_GUIDES = "guide/GET_GUIDES";
const GET_GUIDES_ERROR = "guide/GET_GUIDES_ERROR";
const GET_GUIDES_SUCCESS = "guide/GET_GUIDES_SUCCESS";

// Action interfaces
interface IGuidesRequest extends Action<typeof GET_GUIDES> {}
interface IGuidesError extends Action<typeof GET_GUIDES_ERROR> {}
interface IGuidesSuccess extends Action<typeof GET_GUIDES_SUCCESS> {
  payload: IGuide[];
}

type GuideActionTypes = IGuidesRequest | IGuidesError | IGuidesSuccess;

interface IGuideState {
  loading: boolean;
  error: boolean;
  success: boolean;
  guides: IGuide[];
}

// Initial state
const initialState: IGuideState = {
  loading: false,
  error: false,
  success: false,
  guides: [],
};

const guideReducer: Reducer<IGuideState, GuideActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GET_GUIDES:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case GET_GUIDES_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
    case GET_GUIDES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        guides: action.payload,
      };
    default:
      return state;
  }
};
export default guideReducer;

// Actions
export const getGuides: ActionCreator<
  ThunkAction<void, IGuideState, void, IGuidesRequest | IGuidesError | IGuidesSuccess>
> = () => async (dispatch) => {
  dispatch({ type: GET_GUIDES });
  try {
    const apiResponse = await axios.get<IAPIResponse<IGuide[]>>(settings.contentHub.howToGuide);
    dispatch({ type: GET_GUIDES_SUCCESS, payload: apiResponse.data.response });
  } catch (e) {
    dispatch({ type: GET_GUIDES_ERROR });
  }
};
