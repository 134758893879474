import type { IDictionary } from "@ducks/types";

export function interpolate(str: string, params: { [key: string]: string }) {
  for (const [key, value] of Object.entries(params)) {
    const regex = RegExp(`{${key}}`, "g");
    let matches;
    while ((matches = regex.exec(str)) !== null) str = str.replace(matches[0], value);
  }
  return str;
}

export function getEncodedUrl(url: string) {
  try {
    return new URL(url).href;
  } catch (e) {
    return url;
  }
}

export function dedupe<T>(array: Array<T>) {
  return array.filter((item, index) => array.indexOf(item) === index);
}

export function mapKeyToValue<T = string>(key: string, dictionary: IDictionary<T>) {
  if (typeof key !== "string") return null;
  const attribute = key.toLowerCase();
  if (attribute && dictionary[attribute]) return dictionary[attribute];
  return null;
}

export function getValuesFromMap<T = string>(keys: string[], dictionary: IDictionary<T>) {
  return keys.reduce((acc, key) => {
    const value = mapKeyToValue(key, dictionary);
    if (value) acc.push(value);
    return acc;
  }, [] as T[]);
}
