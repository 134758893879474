// https://material.io/resources/color/#!/?view.left=1&view.right=1&secondary.color=e6007d&secondary.text.color=ffffff&primary.color=11162d

export const astro = "#e6007d";

export const bgDefault = "#fff";
export const bgPaper = "#fff";

export const fontColor = "#010414";
export const headerColor = "#000";

export const error = "#ff0000";

export const grey = {
  100: "#f0f0f0",
  300: "#d8d8d8",
  400: "#bbb",
  600: "#888",
};

export const selectColor = "#1d7fe3";
