import React, { FunctionComponent, Suspense, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Footer, Header, LiveChat, NavProvider } from "acm-components";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { getCLS, getFID, getLCP } from "web-vitals";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import ScrollToTop from "@components/ScrollToTop";
import Spinner from "@components/Spinner";

import type { IAppRoutes, IMetaConfig } from "@ducks/config/types";
import { getSwimlanesConfig } from "@ducks/swimlanes";
import { getConfig } from "@ducks/config";

import * as analytics from "@analytics";
import type { AppState } from "@store";
import settings from "@settings";
import theme from "../theme";

import "@acm-components/css";

const Home = React.lazy(() => import("@containers/Home"));
const Channel = React.lazy(() => import("@containers/Channel"));
const Details = React.lazy(() => import("@containers/Details"));
const NotFound = React.lazy(() => import("@components/NotFound"));
const HowToGuide = React.lazy(() => import("@containers/HowToGuide"));

interface IAppState {
  routes: IAppRoutes;
  meta: IMetaConfig;
}

const App: FunctionComponent = () => {
  const dispatch = useDispatch();
  const state = useSelector<AppState, IAppState>((state) => ({
    routes: state.config.app.routes,
    meta: state.config.meta,
  }));
  const { routes, meta } = state;

  const sendMetricsToGA = (metric: any) => analytics.sendToGA(metric.name, metric.delta, metric.id);

  useEffect(() => {
    dispatch(getConfig());
    dispatch(getSwimlanesConfig());
  }, [dispatch]);

  useEffect(() => {
    getFID(sendMetricsToGA);
    getCLS(sendMetricsToGA);
    getLCP(sendMetricsToGA);
  }, []);

  const renderMeta = useCallback(() => {
    const { titleTemplate, thumbnail, ogType, twitterCard, twitterSite, listing } = meta;

    const { description, keywords } = listing.categories.homepage;

    return (
      <Helmet titleTemplate={titleTemplate}>
        <script type="text/javascript" src={settings.config.interactiveStudioBeacon} />

        {settings.navEnv === "production" && <script type="text/javascript" src={settings.config.newRelic} />}

        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:image" content={thumbnail} />
        <meta property="og:type" content={ogType} />
        <meta name="twitter:card" content={twitterCard} />
        <meta name="twitter:image" content={thumbnail} />
        <meta name="twitter:site" content={twitterSite} />
      </Helmet>
    );
  }, [meta]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <NavProvider portal="acm" environment={settings.navEnv}>
        {({ config: navConfig }) => (
          <>
            <Header config={navConfig.header} hideLanguageSwitcher />
            <Suspense fallback={<Spinner />}>
              <main>
                {renderMeta()}
                <Router>
                  <ScrollToTop />
                  <Routes>
                    <Route path="featured" element={<Navigate to="/"></Navigate>} />
                    <Route path={routes.howToGuide} element={<HowToGuide />} />

                    <Route path={routes.details} element={<Details />} />

                    <Route path={routes.channel} element={<Channel />} />

                    <Route path={routes.listing} element={<Home />} />
                    <Route path="/search" element={<Home />} />
                    <Route path="/" element={<Home />} />
                    <Route element={<NotFound />}></Route>
                  </Routes>
                </Router>
              </main>

              <LiveChat config={navConfig.liveChat} />
            </Suspense>
            <Footer config={navConfig.footer} />
          </>
        )}
      </NavProvider>
    </MuiThemeProvider>
  );
};

export default App;
