import React, { FunctionComponent } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(
  createStyles({
    wrapper: {
      position: "fixed",
      top: "calc(40vh - 20px)",
      left: "calc(50vw - 20px)",
      display: "flex",
      placeItems: "center",
    },
  })
);

const Spinner: FunctionComponent = () => {
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <CircularProgress color="primary" />
    </div>
  );
};

export default Spinner;
