import { LISTINGLAYOUT } from "./types";

// General
export const ROUTES = {
  listing: "/:type",
  details: "/details/:asset",
  channel: "/channels/:id",
  howToGuide: "/how-to-guide",
};
export const BREADCRUMBS_SEPARATOR = ">";
export const BREADCRUMBS_DEFAULT_CATEGORY = "channels";
export const SHOWTIMES_TIMEZONE = 8;
export const MIN_CHANNEL_CATEGORIES = 8;

// Listing page config
export const SHOW_BANNER = true;

export const BANNER_DM_CONFIG = {
  uiStartScreenInfo: false,
  uiLogo: false,
  stopOnUnmount: false,
  height: "100%",
  width: "100%",
  url: "https://www.dailymotion.com/video/",
};
export const BANNER_AUTOPLAY_DURATION = 8000;
export const DEFAULT_BANNER_BACKGROUND = "#333";
export const DEFAULT_LIST_TYPE = "channels";
export const LIST_TYPE_URL = {
  first: "astro-first",
  best: "astro-best",
  channels: "channels",
  movie: "movies",
  show: "shows",
  kid: "kids",
};
export const LISTING_MODE = {
  first: LISTINGLAYOUT.poster,
  best: LISTINGLAYOUT.poster,
  channels: LISTINGLAYOUT.channel,
  movie: LISTINGLAYOUT.card,
  show: LISTINGLAYOUT.card,
  kid: LISTINGLAYOUT.card,
};
export const LISTING_CARD_COLUMNS = {
  mobile: 1,
  tablet: 2,
  desktop: 3,
};
export const LISTING_POSTER_COLUMNS = {
  mobile: 2,
  tablet: 4,
  desktop: 7,
};
export const LISTING_CHANNEL_COLUMNS = {
  mobile: 1,
  tablet: 2,
  desktop: 3,
};
export const MAX_CHANNEL_SCHEDULE = 3;
export const SWIMLANE_CARD_COLUMNS = 4;
export const SWIMLANE_POSTER_COLUMNS = 7;

// Details page config
export const DETAILS_ICONS = {
  u: "U",
  p13: "P13",
  "18": "18",
  "21": "21",
  hd: "HD",
  uhd: "UHD",
  "3d": "3D",
  subtitle: "...",
  multipleAudio: "I-II",
  vod: "VOD",
  astroGo: "GO",
};
export const DETAILS_MAX_CAST_NUMBER = 5;
export const WHATSAPP_URL = "https://wa.me/{phone}";
export const WHATSAPP_PHONE_NUMBER = "60395433838";
export const DETAILS_MAX_LINEAR_SHOWTIMES = 10;
export const DETAILS_MAX_LINEAR_EPISODES = 7;

// Filter sidebar config
export const FILTER_VISIBILITY = {
  mustwatch: false,
  first: false,
  best: false,
  movie: false,
  show: false,
  kid: false,
  channels: true,
};

// How-To Guide page config
export const HOW_TO_GUIDE_SHOW_BANNER = false;

// Meta config
export const META_TITLE_TEMPLATE = "%s | Astro Content";
export const META_THUMBNAIL = "https://static02.astro.com.my/astro/media/astromain/packagepromotion/astro_share_1.png";
export const META_OG_TYPE = "article";
export const META_TWITTER_CARD = "summary";
export const META_TWITTER_SITE = "astrocontent";

//url params for EarlyBird and comingSoon for AFAB
export const movieTypeFilter = "earlyBird,comingSoon";
