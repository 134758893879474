import { CSSProperties } from "@material-ui/styles";

type GlobalStyles = {
  "@font-face"?: CSSProperties["@font-face"];
} & Record<string, CSSProperties["@font-face"] | CSSProperties>;

export const globalStyles: GlobalStyles = {
  html: {
    fontSize: "16px",
  },
  body: {
    overflowX: "hidden",
    WebkitTextSizeAdjust: "100%",
    MozTextSizeAdjust: "100%",
    fontSize: "1rem",
  },
  "h1, h2, h3, h4, h5, h6": {
    margin: 0,
    fontWeight: 600,
  },
  h1: {
    fontSize: "3rem",
  },
  h2: {
    fontSize: "2rem",
    lineHeight: 1.25,
    "@media (max-width: 959.95px)": {
      fontSize: "1.75rem",
      lineHeight: "2rem",
    },
  },
  h3: {
    fontSize: "1.5rem",
    lineHeight: "27px",
  },
  h4: {
    fontSize: "1.25rem",
  },
  h5: {
    fontSize: "1rem",
  },
  h6: {
    fontSize: "0.875rem",
  },
  a: {
    color: "inherit",
    textDecoration: "none",
    outline: "none",
    userSelect: "none",
    WebkitTapHighlightColor: "transparent",
  },
  p: {
    margin: 0,
    fontSize: "1rem",
    lineHeight: 1.5,
  },
  ul: {
    margin: 0,
    paddingLeft: 0,
    listStyle: "none",
  },
  ol: {
    margin: 0,
  },
  li: {
    fontSize: "1rem",
    lineHeight: 1.5,
  },
  strong: {
    fontWeight: 800,
  },
};
