import axios from "axios";
import { Action, ActionCreator, Reducer } from "redux";
import { ThunkAction } from "redux-thunk";

import settings from "@settings";
import type { IBanners, IBanner, IBannerItem } from "./types";
import { IAPIResponse } from "@lib/api";
import { interpolate } from "@lib/common";
import { FetchStatus } from "@ducks/types";
import type { AppState } from "@store";

const GET_BANNERS = "banner/GET_BANNERS";
const GET_BANNERS_ERROR = "banner/GET_BANNERS_ERROR";
const GET_BANNERS_SUCCESS = "banner/GET_BANNERS_SUCCESS";
const UPDATE_HERO_BACKGROUND = "banner/UPDATE_HERO_BACKGROUND";

// Action interfaces
interface IBannersRequest extends Action<typeof GET_BANNERS> {
  payload: string;
}
interface IBannersError extends Action<typeof GET_BANNERS_ERROR> {
  payload: {
    listType: string;
    errorCode?: string;
  };
}
interface IBannersSuccess extends Action<typeof GET_BANNERS_SUCCESS> {
  payload: {
    listType: string;
    items: IBannerItem[];
  };
}
interface IUpdateHeroBackground extends Action<typeof UPDATE_HERO_BACKGROUND> {
  payload: {
    heroBg: string;
    heroBgColor: string;
    heroBgOpacity: string;
  };
}

type BannerActionTypes = IBannersRequest | IBannersError | IBannersSuccess | IUpdateHeroBackground;

interface IBannerState {
  banners: IBanners;
  heroBg: string;
  heroBgColor: string;
  heroBgOpacity: string;
}

// Initial state
const initialState: IBannerState = {
  banners: {},
  heroBg: "",
  heroBgColor: "#333",
  heroBgOpacity: "",
};

// Reducer
const bannerReducer: Reducer<IBannerState, BannerActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANNERS:
      return {
        ...state,
        banners: {
          ...state.banners,
          [action.payload]: {
            fetchStatus: FetchStatus.Loading,
            items: [],
          },
        },
      };
    case GET_BANNERS_ERROR: {
      const banner: IBanner = {
        fetchStatus: FetchStatus.Error,
        items: [],
      };
      if (action.payload.errorCode) banner.errorCode = action.payload.errorCode;
      return {
        ...state,
        banners: {
          ...state.banners,
          [action.payload.listType]: banner,
        },
      };
    }
    case GET_BANNERS_SUCCESS:
      return {
        ...state,
        banners: {
          ...state.banners,
          [action.payload.listType]: {
            fetchStatus: FetchStatus.Success,
            items: action.payload.items,
          },
        },
      };
    case UPDATE_HERO_BACKGROUND:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default bannerReducer;

// Actions
export const getBanners: ActionCreator<
  ThunkAction<void, AppState, void, IBannersRequest | IBannersError | IBannersSuccess>
> = (listType: string) => async (dispatch, getState) => {
  if (getState().banner.banners[listType]) {
    const data = getState().banner.banners[listType];
    if (data.fetchStatus === FetchStatus.Success) return;
  }
  const _listType = listType === "mustwatch" ? "channels" : listType;
  dispatch({
    type: GET_BANNERS,
    payload: _listType,
  });
  try {
    const path = interpolate(settings.contentHub.banners, { listType });
    const apiResponse = await axios.get<IAPIResponse<IBannerItem[]>>(path);
    dispatch({
      type: GET_BANNERS_SUCCESS,
      payload: {
        listType: _listType,
        items: apiResponse.data.response,
      },
    });
  } catch (e) {
    dispatch({
      type: GET_BANNERS_ERROR,
      payload: { listType: _listType },
    });
  }
};

export const updateHeroBackground: ActionCreator<IUpdateHeroBackground> = (
  background: string,
  color: string,
  opacity: string
) => ({
  type: UPDATE_HERO_BACKGROUND,
  payload: {
    heroBg: background,
    heroBgColor: color,
    heroBgOpacity: opacity,
  },
});
