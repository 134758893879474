import { push } from "./gtm";

interface IListTypes {
  channels: string;
  first: string;
  best: string;
  movie: string;
  show: string;
  kid: string;
}

// const LIST_TYPE = "Astro Content Hub";
const content_type = "Astro Content Hub";
const LISTTYPES: IListTypes = {
  channels: "Channel",
  first: "Astro First",
  best: "Astro Best",
  movie: "Movies",
  show: "Shows",
  kid: "Kids",
};

// export const category = (category: string, url: string = "") => ({
//   "data-list-type": LIST_TYPE,
//   "data-content-type": "Main Navigation",
//   "data-content-title": category,
//   "data-content-id": url
// });

// export const breadcrumb = (title: string, level: number) => ({
//   "data-list-type": LIST_TYPE,
//   "data-content-type": "Breadcrumb",
//   "data-content-title": title,
//   "data-content-id": level.toString()
// });

// export const banner = (params: {
//   category: string;
//   title: string;
//   id: string;
//   genre: string;
//   index: number
// }) => ({
//   "data-list-type": LIST_TYPE,
//   "data-content-type": `${params.category} Hero Slides`,
//   "data-content-title": params.title,
//   "data-content-id": params.id,
//   "data-content-category": params.category,
//   "data-content-genre": params.genre || "NA",
//   "data-list-position": params.index.toString()
// });

// export const listing = (params: {
//   index: number;
//   category: string;
//   title: string;
//   id: string;
//   genre: string
// }) => ({
//   "data-list-type": LIST_TYPE,
//   "data-list-position": params.index.toString(),
//   "data-content-type": `${params.category} Listing`,
//   "data-content-title": params.title,
//   "data-content-id": params.id,
//   "data-content-category": params.category,
//   "data-content-genre": params.genre || "NA"
// });

// export const channelListing = (params: {
//   index: number;
//   stbNumber: string;
//   title: string;
//   id: string;
// }) => ({
//   "data-list-type": LIST_TYPE,
//   "data-list-position": params.index.toString(),
//   "data-content-type": "Channels Listing",
//   "data-content-title": `CH${params.stbNumber} - ${params.title}`,
//   "data-content-id": params.id
// });

// export const moreButton = (category: string, page: number) => ({
//   "data-list-type": LIST_TYPE,
//   "data-content-type": "View More",
//   "data-content-title": `Page ${page}`,
//   "data-content-category": category
// });

// export const howToGuideLink = (title: string, url: string) => ({
//   "data-list-type": LIST_TYPE,
//   "data-content-type": "How To Guide",
//   "data-content-title": title,
//   "data-content-id": url
// });

// export const tncLink = (title: string, url: string) => ({
//   "data-list-type": LIST_TYPE,
//   "data-content-type": "Terms & Conditions",
//   "data-content-title": title,
//   "data-content-id": url
// });

// export const whatsappLink = (title: string, url: string) => ({
//   "data-list-type": LIST_TYPE,
//   "data-content-type": "WhatsApp",
//   "data-content-title": title,
//   "data-content-id": url
// });

// export const backButton = (title: string, url: string) => ({
//   "data-list-type": LIST_TYPE,
//   "data-content-type": "Back Button Content Details",
//   "data-content-title": title,
//   "data-content-id": url
// });

// export const howToGuideBackButton = (buttonText: string, url: string) => ({
//   "data-list-type": LIST_TYPE,
//   "data-content-type": "Back Button How To Guide",
//   "data-content-title": buttonText,
//   "data-content-id": url
// });

export const trackNavigation = (title: string, url: string) => {
  push({
    event: "navEvent",
    content_type,
    content_title: title,
    content_list_type: "Main Navigation",
    content_category: "NA",
    content_id: url,
  });
};

export const trackBreadcrumb = (title: string, level: number) => {
  push({
    event: "navEvent",
    content_type,
    content_title: title,
    content_list_type: "Breadcrumb",
    content_category: "NA",
    content_id: level.toString(),
  });
};

export const trackCategory = (title: string) => {
  push({
    event: "viewEvent",
    content_type,
    content_title: title,
  });
};

export const trackBanner = (params: { category: string; title: string; id: string; genre?: string; index: number }) => {
  push({
    event: "clickEvent",
    content_type,
    content_title: params.title,
    content_id: params.id,
    content_category: params.category,
    content_list_type: `${params.category} Hero Slides`,
    content_genre: params.genre || "NA",
    content_list_position: params.index.toString(),
  });
};

export const trackTrailer = (params: {
  category: string;
  title: string;
  id: string;
  genre?: string;
  index: number;
}) => {
  push({
    event: "clickEvent",
    content_type,
    content_title: params.title,
    content_id: params.id,
    content_category: params.category,
    content_list_type: `${params.category} Trailer`,
    content_genre: params.genre || "NA",
    content_list_position: "NA",
  });
};

export const trackSlideEvent = (index: number, category: string) => {
  push({
    event: "slideEvent",
    content_type,
    content_title: `Hero Slide ${index.toString()}`,
    content_list_type: "Hero Slide",
    content_category: category,
  });
};

export const trackFeaturedChannel = (params: { title: string; id: string; index: number }) => {
  push({
    event: "clickEvent",
    content_type,
    content_title: params.title,
    content_id: params.id,
    content_category: "NA",
    content_list_type: "Featured Channels",
    content_genre: "NA",
    content_list_position: params.index.toString(),
  });
};

export const trackListing = (params: {
  title: string;
  id: string;
  category: string;
  genre: string;
  index: number;
  swimlane: boolean;
}) => {
  push({
    event: "clickEvent",
    content_type,
    content_title: params.title,
    content_id: params.id,
    content_category: params.category,
    content_list_type: `${params.swimlane ? "Swimlane" : params.category} Listing`,
    content_genre: params.genre,
    content_list_position: params.index.toString(),
  });
};

export const trackChannelListing = (params: { stbNumber: string; title: string; id: string; index: number }) => {
  push({
    event: "clickEvent",
    content_type,
    content_title: `CH${params.stbNumber} - ${params.title}`,
    content_id: params.id,
    content_category: "Channels",
    content_list_type: "Channels Listing",
    content_genre: "NA",
    content_list_position: params.index.toString(),
  });
};

export const trackFilterLink = (category: string) => {
  push({
    event: "navEvent",
    content_type,
    content_title: "Filter",
    content_list_type: "Filter Link",
    content_category: LISTTYPES[category as keyof IListTypes],
    content_id: "NA",
  });
};

export const trackApplyFilter = (filters: string[], listType: string) => {
  push({
    event: "navEvent",
    content_type,
    content_title: filters.join(","),
    content_list_type: `${LISTTYPES[listType as keyof IListTypes]} Filter`,
    content_category: "Apply Filter",
    content_id: "NA",
  });
};

export const trackResetFilter = (filters: string[], listType: string) => {
  push({
    event: "navEvent",
    content_type,
    content_title: filters.join(","),
    content_list_type: `${LISTTYPES[listType as keyof IListTypes]} Filter`,
    content_category: "Reset Filter",
    content_id: "NA",
  });
};

export const trackSwimlaneViewAll = (title: string) => {
  push({
    event: "navEvent",
    content_type,
    content_title: title,
    content_list_type: "View All",
    content_category: "Swimlane View All",
    content_id: "NA",
  });
};

export const trackMoreButton = (category: string, page: number) => {
  push({
    event: "navEvent",
    content_type,
    content_list_type: "View More",
    content_title: `Page ${page}`,
    content_category: category,
    content_id: "NA",
  });
};

export const trackDetails = (params: { title: string; id: string; category: string; genre: string }) => {
  push({
    event: "viewEvent",
    content_type,
    content_title: params.title,
    content_id: params.id,
    content_category: params.category,
    content_genre: params.genre || "NA",
  });
};

export const trackHowToGuideLink = (title: string, url: string) => {
  push({
    event: "navEvent",
    content_type,
    content_title: title,
    content_list_type: "How To Guide",
    content_category: "NA",
    content_id: url,
  });
};

export const trackTncLink = (title: string, url: string) => {
  push({
    event: "navEvent",
    content_type,
    content_title: title,
    content_list_type: "Terms & Conditions",
    content_category: "NA",
    content_id: url,
  });
};

export const trackWhatsAppLink = (title: string, url: string) => {
  push({
    event: "navEvent",
    content_type,
    content_title: title,
    content_list_type: "WhatsApp",
    content_category: "NA",
    content_id: url,
  });
};

export const trackBackButtonLink = (title: string, url: string) => {
  push({
    event: "navEvent",
    content_type,
    content_title: title,
    content_list_type: "Back Button Content Details",
    content_category: "NA",
    content_id: url,
  });
};

export const trackMoreEpisodeLink = (title: string, id: string) => {
  push({
    event: "navEvent",
    content_type,
    content_title: title,
    content_list_type: "More Episodes",
    content_category: "NA",
    content_id: id,
  });
};

export const trackChannelDetails = (params: { id: string; stbNumber: string; title: string }) => {
  push({
    event: "viewEvent",
    content_type,
    content_title: `CH${params.stbNumber} - ${params.title}`,
    content_id: params.id,
    content_category: "Channels",
  });
};

export const trackChannelEPG = (params: { index: number; day: string; stbNumber: string; title: string }) => {
  push({
    event: "clickEvent",
    content_type,
    content_title: `CH${params.stbNumber} - ${params.title}`,
    content_id: `${params.index} - ${params.day}`,
    content_category: "Channels EPG",
    content_list_type: "Channels EPG",
  });
};

export const trackHowToGuideView = () => {
  push({
    event: "viewEvent",
    content_type,
    content_title: "How To Guide",
  });
};

export const trackExpandGuide = (title: string) => {
  push({
    event: "expandEvent",
    content_type,
    content_list_type: "How To Guide",
    content_title: title,
  });
};

export const trackSearchEvent = () => {
  push({
    event: "viewEvent",
    content_type,
    content_title: "Search",
  });
};

export const trackOnSearch = (value: string) => {
  push({
    event: "navEvent",
    content_type,
    content_title: value,
    content_list_type: "Search",
  });
};

export const trackCloseSearch = () => {
  push({
    event: "navEvent",
    content_type,
    content_title: "Close Search",
    content_list_type: "Search",
  });
};

export const trackSearchTab = (tabIndex: number) => {
  const tabName = tabIndex === 0 ? "Channels" : tabIndex === 1 ? "On Demand" : "TV";
  push({
    event: "navEvent",
    content_type,
    content_title: tabName,
    content_list_type: "Search Tab",
  });
};

export const sendToGA = (name: string, delta: number, id: any) => {
  push({
    event: "web-vitals",
    event_category: "Web Vitals",
    event_action: name,
    event_value: Math.round(name === "CLS" ? delta * 1000 : delta),
    event_label: id,
  });
};
