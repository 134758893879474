import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import configReducer from "@ducks/config";
import listingReducer from "@ducks/listing";
import bannerReducer from "@ducks/banner";
import detailsReducer from "@ducks/details";
import guideReducer from "@ducks/guide";
import swimlanesReducer from "@ducks/swimlanes";
import leagueDataReducer from "@ducks/leagueList";

const rootReducer = combineReducers({
  config: configReducer,
  listing: listingReducer,
  banner: bannerReducer,
  details: detailsReducer,
  guide: guideReducer,
  swimlanes: swimlanesReducer,
  leaguelist: leagueDataReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  const middlewares = [thunk];
  const composeEnhancers = composeWithDevTools({ trace: true });
  const middlewareEnhancer = composeEnhancers(applyMiddleware(...middlewares));

  const store = createStore(rootReducer, middlewareEnhancer);
  return store;
}
