import { createTheme } from "@material-ui/core/styles";
import { globalStyles } from "./global";
import * as colors from "./colors";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: colors.astro,
    },
    background: {
      default: colors.bgDefault,
      paper: colors.bgPaper,
    },
    common: {
      black: colors.fontColor,
    },
    text: {
      primary: colors.fontColor,
      secondary: colors.fontColor,
    },
    error: {
      main: colors.error,
    },
    grey: colors.grey,
    secondary: {
      main: colors.selectColor,
    },
  },
  typography: {
    fontFamily: [
      `Mulish`,
      `-apple-system`,
      `BlinkMacSystemFont`,
      `"Segoe UI"`,
      `Roboto`,
      `"Helvetica Neue"`,
      `Arial`,
      "Microsoft YaHei",
      "微软雅黑",
      "STXihei",
      "华文细黑",
      `sans-serif`,
      `"Apple Color Emoji"`,
      `"Segoe UI Emoji"`,
      `"Segoe UI Symbol"`,
    ].join(","),
    button: {
      fontWeight: 600,
    },
  },
  props: {
    MuiGrid: {
      spacing: 2,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      "@media (min-width: 960px)": {
        minHeight: 64,
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        ...globalStyles,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      },
    },
    MuiIconButton: {
      root: {
        padding: "1rem",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
  },
});

export default theme;
