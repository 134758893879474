import axios from "axios";
import { Action, ActionCreator, Reducer } from "redux";
import { ThunkAction } from "redux-thunk";
import MyDate from "@lib/mydate";

import settings from "@settings";
import { RESOLUTION } from "../types";
import {
  IChannelDetailsResponse,
  ILinearDetailsResponse,
  IAfabDetailsResponse,
  IEPGDetailsResponse,
  IDetailsResponse,
  IEPGDetails,
  IDetails,
} from "./types";
import { IAPIResponse } from "@lib/api";
import { interpolate } from "@lib/common";
import type { IListType } from "@ducks/config/types";
import type { ISwimlane } from "@ducks/swimlanes/types";

// Action Types
const GET_DETAILS = "details/GET_DETAILS";
const GET_DETAILS_ERROR = "details/GET_DETAILS_ERROR";
const GET_DETAILS_SUCCESS = "details/GET_DETAILS_SUCCESS";
const GET_AFAB_DETAILS = "details/GET_AFAB_DETAILS";
const GET_AFAB_DETAILS_ERROR = "details/GET_AFAB_DETAILS_ERROR";
const GET_AFAB_DETAILS_SUCCESS = "details/GET_AFAB_DETAILS_SUCCESS";
const RESET_DETAILS = "details/RESET_DETAILS";
const GET_CHANNEL_DETAILS = "details/GET_CHANNEL_DETAILS";
const GET_CHANNEL_DETAILS_ERROR = "details/GET_CHANNEL_DETAILS_ERROR";
const GET_CHANNEL_DETAILS_SUCCESS = "details/GET_CHANNEL_DETAILS_SUCCESS";
const RESET_CHANNEL_DETAILS = "details/RESET_CHANNEL_DETAILS";
const GET_LINEAR_DETAILS = "details/GET_LINEAR_DETAILS";
const GET_LINEAR_DETAILS_ERROR = "details/GET_LINEAR_DETAILS_ERROR";
const GET_LINEAR_DETAILS_SUCCESS = "details/GET_LINEAR_DETAILS_SUCCESS";
const RESET_LINEAR_DETAILS = "details/RESET_LINEAR_DETAILS";
const GET_EPG_DETAILS = "details/GET_EPG_DETAILS";
const GET_EPG_DETAILS_ERROR = "details/GET_EPG_DETAILS_ERROR";
const GET_EPG_DETAILS_SUCCESS = "details/GET_EPG_DETAILS_SUCCESS";
const RESET_EPG_DETAILS = "details/RESET_EPG_DETAILS";
const UPDATE_BREADCRUMBS_LIST_TYPE = "details/UPDATE_BREADCRUMBS_LIST_TYPE";
const UPDATE_BREADCRUMBS_SWIMLANE = "details/UPDATE_BREADCRUMBS_SWIMLANE";

// Action interfaces
interface IAfabDetailsRequest extends Action<typeof GET_AFAB_DETAILS> {}
interface IAfabDetailsError extends Action<typeof GET_AFAB_DETAILS_ERROR> {}
interface IAfabDetailsSuccess extends Action<typeof GET_AFAB_DETAILS_SUCCESS> {
  payload: IDetails;
}

interface IDetailsRequest extends Action<typeof GET_DETAILS> {}
interface IDetailsError extends Action<typeof GET_DETAILS_ERROR> {}
interface IDetailsSuccess extends Action<typeof GET_DETAILS_SUCCESS> {
  payload: IDetails;
}
interface IChannelDetailsRequest extends Action<typeof GET_CHANNEL_DETAILS> {}
interface IChannelDetailsError extends Action<typeof GET_CHANNEL_DETAILS_ERROR> {}
interface IChannelDetailsSuccess extends Action<typeof GET_CHANNEL_DETAILS_SUCCESS> {
  payload: IChannelDetailsResponse;
}
interface ILinearDetailsRequest extends Action<typeof GET_LINEAR_DETAILS> {}
interface ILinearDetailsError extends Action<typeof GET_LINEAR_DETAILS_ERROR> {}
interface ILinearDetailsSuccess extends Action<typeof GET_LINEAR_DETAILS_SUCCESS> {
  payload: IDetails;
}
interface IEPGDetailsRequest extends Action<typeof GET_EPG_DETAILS> {}
interface IEPGDetailsError extends Action<typeof GET_EPG_DETAILS_ERROR> {}
interface IEPGDetailsSuccess extends Action<typeof GET_EPG_DETAILS_SUCCESS> {
  payload: IEPGDetails;
}
interface IResetDetails extends Action<typeof RESET_DETAILS> {}
interface IResetChannelDetails extends Action<typeof RESET_CHANNEL_DETAILS> {}
interface IResetLinearDetails extends Action<typeof RESET_LINEAR_DETAILS> {}
interface IResetEPGDetails extends Action<typeof RESET_EPG_DETAILS> {}
interface IUpdateBreadcrumbsListType extends Action<typeof UPDATE_BREADCRUMBS_LIST_TYPE> {
  payload: IListType;
}
interface IUpdateBreadcrumbsSwimlane extends Action<typeof UPDATE_BREADCRUMBS_SWIMLANE> {
  payload: ISwimlane;
}

type DetailsActionTypes =
  | IAfabDetailsRequest
  | IAfabDetailsError
  | IAfabDetailsSuccess
  | IDetailsRequest
  | IDetailsError
  | IDetailsSuccess
  | IResetDetails
  | IChannelDetailsRequest
  | IChannelDetailsError
  | IChannelDetailsSuccess
  | IResetChannelDetails
  | ILinearDetailsRequest
  | ILinearDetailsError
  | ILinearDetailsSuccess
  | IResetLinearDetails
  | IEPGDetailsRequest
  | IEPGDetailsError
  | IEPGDetailsSuccess
  | IResetEPGDetails
  | IUpdateBreadcrumbsListType
  | IUpdateBreadcrumbsSwimlane;

interface IDetailsState {
  loading: boolean;
  error: boolean;
  success: boolean;
  details: IDetails | null;
  channelDetails: IChannelDetailsResponse | null;
  epgLoading: boolean;
  epgError: boolean;
  epgSuccess: boolean;
  epgDetails: IEPGDetails | null;
  detailsListType: IListType | null;
  detailsSwimlane: ISwimlane | null;
}

// Initial state
const initialState: IDetailsState = {
  loading: false,
  error: false,
  success: false,
  details: null,
  channelDetails: null,
  epgLoading: false,
  epgError: false,
  epgSuccess: false,
  epgDetails: null,
  detailsListType: null,
  detailsSwimlane: null,
};

// Reducer
const detailsReducer: Reducer<IDetailsState, DetailsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GET_DETAILS:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        details: null,
      };
    case GET_AFAB_DETAILS:
    case GET_LINEAR_DETAILS:
    case GET_CHANNEL_DETAILS:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case GET_DETAILS_ERROR:
    case GET_AFAB_DETAILS_ERROR:
    case GET_LINEAR_DETAILS_ERROR:
    case GET_CHANNEL_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
    case GET_DETAILS_SUCCESS:
    case GET_AFAB_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        details: action.payload,
      };
    case GET_LINEAR_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        details: action.payload,
      };
    case GET_CHANNEL_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        channelDetails: action.payload,
      };
    case GET_EPG_DETAILS:
      return {
        ...state,
        epgLoading: true,
        epgError: false,
        epgSuccess: false,
      };
    case GET_EPG_DETAILS_ERROR:
      return {
        ...state,
        epgLoading: false,
        epgError: true,
        epgSuccess: false,
      };
    case GET_EPG_DETAILS_SUCCESS:
      return {
        ...state,
        epgLoading: false,
        epgError: false,
        epgSuccess: true,
        epgDetails: action.payload,
      };
    case RESET_DETAILS:
    case RESET_LINEAR_DETAILS:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        details: null,
      };
    case RESET_CHANNEL_DETAILS:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        channelDetails: null,
      };
    case RESET_EPG_DETAILS:
      return {
        ...state,
        epgLoading: false,
        epgError: false,
        epgSuccess: false,
        epgDetails: null,
      };
    case UPDATE_BREADCRUMBS_LIST_TYPE:
      return {
        ...state,
        detailsListType: action.payload,
        detailsSwimlane: null,
      };
    case UPDATE_BREADCRUMBS_SWIMLANE:
      return {
        ...state,
        detailsListType: null,
        detailsSwimlane: action.payload,
      };
    default:
      return state;
  }
};
export default detailsReducer;

// Actions
export const getAfabDetails: ActionCreator<
  ThunkAction<void, IDetailsState, void, IAfabDetailsRequest | IAfabDetailsError | IAfabDetailsSuccess>
> = (titleId: string) => async (dispatch) => {
  dispatch({ type: GET_AFAB_DETAILS });
  try {
    const url = interpolate(settings.contentHub.afabDetails, { titleId: titleId.toString() });
    const apiResponse = await axios.get<IAPIResponse<IAfabDetailsResponse>>(url);
    const d = apiResponse.data.response;
    if (!d) throw new Error("Details not found");

    const payload: any = {
      id: d.id,
      type: d.type,
      packageAsset: undefined,
      category: d.genre || "",
      title: d.title || "",
      synopsis: d.summary || "",
      casts: d.cast || "",
      director: "",
      classifications: d.certification || "",
      genres: d.genre || [],
      duration: d.showDuration || 0,
      subtitles: d.subtitles || [],
      languages: [d.language] || "",
      imageUrl: d.landscapeImageUrl || "",
      publishFrom: d.movieStartDate || "",
      publishTo: d.movieEndDate || "",
      resolution: {
        isSD: d.isSD ? "SD" : null,
        isHD: d.isHD ? "HD" : null,
        isUHD: d.isUHD ? "UHD" : null,
      },
      note: d.note,
      hasOnDemand: true,
      whatsappShortcode: d.whatsappShortcode,
      normalPrice: d.normalPrice,
      earlyBirdPrice: d.earlyBirdPrice,
      normalRentalDuration: d.normalRentalDuration,
      earlyBirdRentalDuration: d.earlyBirdRentalDuration,
    };

    dispatch({ type: GET_AFAB_DETAILS_SUCCESS, payload });
  } catch (e) {
    // TODO: error messages
    dispatch({ type: GET_AFAB_DETAILS_ERROR });
  }
};

export const getDetails: ActionCreator<
  ThunkAction<void, IDetailsState, void, IDetailsRequest | IDetailsError | IDetailsSuccess>
> = (packageAsset: string) => async (dispatch) => {
  dispatch({ type: GET_DETAILS });
  try {
    const url = `${settings.contentHub.details}?packageAsset=${packageAsset}`;
    const apiResponse = await axios.get<IAPIResponse<IDetailsResponse>>(url);
    const d = apiResponse.data.response;
    if (!d) throw new Error("Details not found");

    const { cast = "", certification = "", formatLabel, audioLanguages = [], language = "" } = d;

    let resolution: RESOLUTION = RESOLUTION.sd;
    if (formatLabel !== null) {
      if (formatLabel.toLowerCase().indexOf("uhd") >= 0) resolution = RESOLUTION.uhd;
      else if (formatLabel.toLowerCase().indexOf("hd") >= 0) resolution = RESOLUTION.hd;
    }

    const casts = cast.split("|").map((c) => c.trim());

    const durationMyDate = MyDate.duration({ seconds: d.duration });
    const duration = durationMyDate.isValid() ? Math.floor(durationMyDate.asMinutes()) : 0;

    const languages = audioLanguages.length ? audioLanguages : [language];

    const payload: IDetails = {
      id: d.id,
      packageAsset: d.packageAsset,
      category: d.filter || "",
      title: d.title || d.packageName || "",
      synopsis: d.longSynopsis || d.shortSynopsis || "",
      casts,
      director: d.director || "",
      classifications: [certification],
      genres: d.subFilter || [],
      duration,
      subtitles: d.subtitles || [],
      languages,
      imageUrl: d.imageUrl || "",
      publishFrom: d.publishFrom || "",
      publishTo: d.publishTo || "",
      resolution,
      hasOnDemand: true,
    };
    if (d.linearChannelAvailability) payload.linearChannelAvailability = d.linearChannelAvailability;
    if (d.price) payload.price = d.price;
    if (d.priceForDays) payload.priceForDays = d.priceForDays;

    dispatch({ type: GET_DETAILS_SUCCESS, payload });
  } catch (e) {
    // TODO: error messages
    dispatch({ type: GET_DETAILS_ERROR });
  }
};

export const getLinearDetails: ActionCreator<
  ThunkAction<void, IDetailsState, void, ILinearDetailsRequest | ILinearDetailsError | ILinearDetailsSuccess>
> = (siTrafficKey: string) => async (dispatch) => {
  dispatch({ type: GET_LINEAR_DETAILS });
  try {
    const url = `${settings.contentHub.linearDetail}?siTrafficKey=${siTrafficKey}`;
    const apiResponse = await axios.get<IAPIResponse<ILinearDetailsResponse>>(url);
    const d = apiResponse.data.response;
    if (!d) throw new Error("Linear details not found");

    const { cast = "" } = d;

    const casts = cast
      .replace(/\.$/, "")
      .split(",")
      .map((c) => c.trim());

    const durationMyDate = MyDate.duration(d.duration || "");
    const duration = durationMyDate.isValid() ? Math.floor(durationMyDate.asMinutes()) : 0;

    const payload: IDetails = {
      id: d.eventId,
      siTrafficKey: d.siTrafficKey,
      programmeId: d.programmeId || "",
      episodeId: d.episodeId || "",
      category: d.filter || "",
      title: d.title || "",
      synopsis: d.longSynopsis || d.shortSynopsis || "",
      casts,
      director: d.director || "",
      classifications: d.certification ? [d.certification] : [],
      genres: d.subFilter || [],
      duration,
      subtitles: [],
      languages: [],
      linearChannelAvailability: [],
      imageUrl: d.imageUrl || "",
      resolution: d.isHd ? RESOLUTION.hd : RESOLUTION.sd,
      hasOnDemand: false,
      stbNumber: d.channelStbNumber || "",
      channelTitle: d.channelTitle || "",
      channelUrl: d.channelUrl || "",
      upsellDetails: d.upsellDetails || [],
    };

    dispatch({ type: GET_LINEAR_DETAILS_SUCCESS, payload });
  } catch (e) {
    // TODO: error messages
    dispatch({ type: GET_LINEAR_DETAILS_ERROR });
  }
};

export const getEPGDetails: ActionCreator<
  ThunkAction<void, IDetailsState, void, IEPGDetailsRequest | IEPGDetailsError | IEPGDetailsSuccess>
> = (programmeId: string, episodeId: string) => async (dispatch) => {
  dispatch({ type: GET_EPG_DETAILS });
  try {
    const url = `${settings.contentHub.epgDetail}?programmeId=${programmeId}&episodeId=${episodeId}`;
    const apiResponse = await axios.get<IAPIResponse<IEPGDetailsResponse>>(url);
    const d = apiResponse.data.response;
    if (!d) throw new Error("EPG details not found");

    const { showTimes = [], otherShows = [] } = d;

    const payload: IEPGDetails = {
      showtimes: showTimes.map((s) => ({
        title: s.title || "",
        datetime: s.datetimeInUtc,
        duration: s.duration,
        stbNumber: s.channelStbNumber || "",
        channelTitle: s.channelTitle,
      })),
      episodes: otherShows.map((s) => {
        const matchedString = s.title.match(/^(.*)(Ep\d+)$/);
        if (matchedString && matchedString.length === 3)
          return {
            siTrafficKey: s.siTrafficKey,
            fullTitle: s.title || "",
            title: matchedString[1].trim(),
            episode: matchedString[2].trim(),
            url: s.detailUrl || "",
          };
        else
          return {
            siTrafficKey: s.siTrafficKey,
            fullTitle: s.title || "",
            title: s.title || "",
            url: s.detailUrl || "",
          };
      }),
    };

    dispatch({ type: GET_EPG_DETAILS_SUCCESS, payload });
  } catch (e) {
    dispatch({ type: GET_EPG_DETAILS_ERROR });
  }
};

export const getChannelDetails: ActionCreator<
  ThunkAction<void, IDetailsState, void, IChannelDetailsRequest | IChannelDetailsError | IChannelDetailsSuccess>
> = (id: number | string) => async (dispatch) => {
  dispatch({ type: GET_CHANNEL_DETAILS });
  try {
    const url = interpolate(settings.contentHub.channelDetails, { id: id.toString() });
    const apiResponse = await axios.get<IAPIResponse<IChannelDetailsResponse>>(url);
    dispatch({ type: GET_CHANNEL_DETAILS_SUCCESS, payload: apiResponse.data.response });
  } catch (e) {
    // TODO: error messages
    dispatch({ type: GET_CHANNEL_DETAILS_ERROR });
  }
};

export const resetDetails: ActionCreator<IResetDetails> = () => ({
  type: RESET_DETAILS,
});

export const resetChannelDetails: ActionCreator<IResetChannelDetails> = () => ({
  type: RESET_CHANNEL_DETAILS,
});

export const resetEPGDetails: ActionCreator<IResetEPGDetails> = () => ({
  type: RESET_EPG_DETAILS,
});

// TODO: duplicated logic
export const updateBreadcrumbsListType: ActionCreator<IUpdateBreadcrumbsListType> = (listType: IListType) => ({
  type: UPDATE_BREADCRUMBS_LIST_TYPE,
  payload: listType,
});

export const updateBreadcrumbsSwimlane: ActionCreator<IUpdateBreadcrumbsSwimlane> = (swimlane: ISwimlane) => ({
  type: UPDATE_BREADCRUMBS_SWIMLANE,
  payload: swimlane,
});
