import axios from "axios";
import { Action, ActionCreator, Reducer } from "redux";
import { ThunkAction } from "redux-thunk";

import settings from "@settings";
import type { IItem, IListing, IPagination, IChannelListing, IFilter, IEBItem } from "./types";
import type { IListType } from "@ducks/config/types"; // TODO: move to general types
import type { ISwimlane } from "@ducks/swimlanes/types"; // TODO: move to general types
import type { IAPIResponse } from "@lib/api";
import { interpolate } from "@lib/common";

// Action types
const GET_LISTING = "listing/GET_LISTING";
const GET_LISTING_ERROR = "listing/GET_LISTING_ERROR";
const GET_LISTING_SUCCESS = "listing/GET_LISTING_SUCCESS";
const GET_NEXT_LISTING = "listing/GET_NEXT_LISTING";
const GET_NEXT_LISTING_ERROR = "listing/GET_NEXT_LISTING_ERROR";
const GET_NEXT_LISTING_SUCCESS = "listing/GET_NEXT_LISTING_SUCCESS";
const GET_CHANNEL_LISTING = "listing/GET_CHANNEL_LISTING";
const GET_CHANNEL_LISTING_ERROR = "listing/GET_CHANNEL_LISTING_ERROR";
const GET_CHANNEL_LISTING_SUCCESS = "listing/GET_CHANNEL_LISTING_SUCCESS";
const UPDATE_LIST_TYPE = "listing/UPDATE_LIST_TYPE";
const UPDATE_SWIMLANE = "listing/UPDATE_SWIMLANE";
const UPDATE_FILTERS = "listing/UPDATE_FILTERS";
const TOGGLE_FILTER = "listing/TOGGLE_FILTER";
const GET_SEARCH = "listing/GET_SEARCH";
const GET_OD_SEARCH_ERROR = "listing/GET_OD_SEARCH_ERROR";
const GET_OD_SEARCH_SUCCESS = "listing/GET_OD_SEARCH_SUCCESS";
const GET_LINEAR_SEARCH_ERROR = "listing/GET_LINEAR_SEARCH_ERROR";
const GET_LINEAR_SEARCH_SUCCESS = "listing/GET_LINEAR_SEARCH_SUCCESS";
const UPDATE_SEARCH_TAB = "listing/UPDATE_SEARCH_TAB";
const UPDATE_INVALID_SEARCH = "listing/UPDATE_INVALID_SEARCH";
const UPDATE_INVALID_SEARCH_INPUT = "listing/UPDATE_INVALID_SEARCH_INPUT";
const SHOULD_DEFAULT_TAB = "listing/SHOULD_DEFAULT_TAB";
const GET_EB_LISTING = "listing/GET_EB_LISTING";
const GET_EB_LISTING_ERROR = "listing/GET_EB_LISTING_ERROR";
const GET_EB_LISTING_SUCCESS = "listing/GET_EB_LISTING_SUCCESS";

// Action interfaces
interface IListingRequest extends Action<typeof GET_LISTING> {}
interface IListingError extends Action<typeof GET_LISTING_ERROR> {}
interface IListingSuccess extends Action<typeof GET_LISTING_SUCCESS> {
  payload: IListing;
}

interface IEBListingRequest extends Action<typeof GET_EB_LISTING> {}
interface IEBListingError extends Action<typeof GET_EB_LISTING_ERROR> {}
interface IEBListingSuccess extends Action<typeof GET_EB_LISTING_SUCCESS> {
  payload: IEBItem[];
}

interface INextListingRequest extends Action<typeof GET_NEXT_LISTING> {}
interface INextListingError extends Action<typeof GET_NEXT_LISTING_ERROR> {}
interface INextListingSuccess extends Action<typeof GET_NEXT_LISTING_SUCCESS> {
  payload: IListing;
}
interface IChannelListingRequest extends Action<typeof GET_CHANNEL_LISTING> {}
interface IChannelListingError extends Action<typeof GET_CHANNEL_LISTING_ERROR> {}
interface IChannelListingSuccess extends Action<typeof GET_CHANNEL_LISTING_SUCCESS> {
  payload: IChannelListing[];
}
interface IUpdateListType extends Action<typeof UPDATE_LIST_TYPE> {
  payload: IListType;
}
interface IUpdateSwimlane extends Action<typeof UPDATE_SWIMLANE> {
  payload: ISwimlane;
}
interface IUpdateFilters extends Action<typeof UPDATE_FILTERS> {
  payload: IFilter[];
}
interface IToggleFilter extends Action<typeof TOGGLE_FILTER> {
  payload: boolean;
}
interface ISearchRequest extends Action<typeof GET_SEARCH> {}
interface IODSearchError extends Action<typeof GET_OD_SEARCH_ERROR> {}
interface ILinearSearchError extends Action<typeof GET_LINEAR_SEARCH_ERROR> {}
interface IODSearchSuccess extends Action<typeof GET_OD_SEARCH_SUCCESS> {
  payload: IItem[];
}
interface ILinearSearchSuccess extends Action<typeof GET_LINEAR_SEARCH_SUCCESS> {
  payload: IItem[];
}
interface IUpdateSearchTab extends Action<typeof UPDATE_SEARCH_TAB> {
  payload: number | false;
}
interface IUpdateInvalidSearch extends Action<typeof UPDATE_INVALID_SEARCH> {
  payload: boolean;
}
interface IUpdateInvalidSearchInput extends Action<typeof UPDATE_INVALID_SEARCH_INPUT> {
  payload: boolean;
}
interface IShouldDefaultTab extends Action<typeof SHOULD_DEFAULT_TAB> {
  payload: boolean;
}

type ListingActionTypes =
  | IListingRequest
  | IListingError
  | IListingSuccess
  | IEBListingRequest
  | IEBListingError
  | IEBListingSuccess
  | INextListingRequest
  | INextListingError
  | INextListingSuccess
  | IChannelListingRequest
  | IChannelListingError
  | IChannelListingSuccess
  | IUpdateListType
  | IUpdateSwimlane
  | IUpdateFilters
  | IToggleFilter
  | ISearchRequest
  | IODSearchError
  | ILinearSearchError
  | IODSearchSuccess
  | ILinearSearchSuccess
  | IUpdateSearchTab
  | IUpdateInvalidSearch
  | IShouldDefaultTab
  | IUpdateInvalidSearchInput;

interface IListingState {
  listType: IListType | null;
  swimlane: ISwimlane | null;
  loading: boolean;
  error: boolean;
  success: boolean;
  channelsSuccess: boolean;
  nextLoading: boolean;
  nextError: boolean;
  nextSuccess: boolean;
  items: IItem[];
  iEBItems: IEBItem[];
  pagination: IPagination | null;
  channels: IChannelListing[];
  isFilterOpened: boolean;
  appliedFilters: IFilter[];
  odSearchResult: IItem[];
  odSearchSuccess: boolean;
  odSearchError: boolean;
  odSearchLoading: boolean;
  linearSearchResult: IItem[];
  linearSearchSuccess: boolean;
  linearSearchError: boolean;
  linearSearchLoading: boolean;
  currentSearchTab: number | false;
  isSearchInvalid: boolean;
  shouldDefaultTab: boolean;
  isSearchInputInvalid: boolean;
}

// Initial state
const initialState: IListingState = {
  listType: null,
  swimlane: null,
  loading: false,
  error: false,
  success: false,
  channelsSuccess: false,
  nextLoading: false,
  nextError: false,
  nextSuccess: false,
  items: [],
  iEBItems: [],
  pagination: null,
  channels: [],
  isFilterOpened: false,
  appliedFilters: [],
  odSearchResult: [],
  odSearchSuccess: false,
  odSearchError: false,
  odSearchLoading: false,
  linearSearchResult: [],
  linearSearchSuccess: false,
  linearSearchError: false,
  linearSearchLoading: false,
  currentSearchTab: false,
  isSearchInvalid: false,
  shouldDefaultTab: true,
  isSearchInputInvalid: false,
};

// Reducer
const listingReducer: Reducer<IListingState, ListingActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GET_LISTING:
    case GET_EB_LISTING:
    case GET_CHANNEL_LISTING:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        iEBItems: [],
      };
    case GET_EB_LISTING_ERROR:
      return {
        ...state,
        loading: false,
      };
    case GET_LISTING_ERROR:
    case GET_CHANNEL_LISTING_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
      };
    case GET_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        items: action.payload.items,
        pagination: action.payload.pagination,
      };
    case GET_EB_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        iEBItems: action.payload,
      };
    case GET_CHANNEL_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        channels: action.payload,
      };
    case GET_NEXT_LISTING:
      return {
        ...state,
        nextLoading: true,
        nextError: false,
        nextSuccess: false,
      };
    case GET_NEXT_LISTING_ERROR:
      return {
        ...state,
        nextLoading: false,
        nextError: true,
        nextSuccess: false,
      };
    case GET_NEXT_LISTING_SUCCESS:
      return {
        ...state,
        nextLoading: false,
        nextError: false,
        nextSuccess: true,
        items: [...state.items, ...action.payload.items],
        pagination: action.payload.pagination,
      };
    case UPDATE_LIST_TYPE:
      return {
        ...state,
        listType: action.payload,
        swimlane: null,
      };
    case UPDATE_SWIMLANE:
      return {
        ...state,
        listType: null,
        swimlane: action.payload,
      };
    case UPDATE_FILTERS:
      return {
        ...state,
        appliedFilters: action.payload,
      };
    case TOGGLE_FILTER:
      return {
        ...state,
        isFilterOpened: action.payload,
      };
    case UPDATE_SEARCH_TAB:
      return {
        ...state,
        currentSearchTab: action.payload,
      };
    case GET_SEARCH:
      return {
        ...state,
        linearSearchLoading: true,
        linearSearchError: false,
        linearSearchSuccess: false,
        odSearchLoading: true,
        odSearchError: false,
        odSearchSuccess: false,
        odSearchResult: [],
        linearSearchResult: [],
      };
    case GET_OD_SEARCH_SUCCESS:
      return {
        ...state,
        odSearchLoading: false,
        odSearchError: false,
        odSearchSuccess: true,
        odSearchResult: action.payload,
      };
    case GET_LINEAR_SEARCH_SUCCESS:
      return {
        ...state,
        linearSearchLoading: false,
        linearSearchError: false,
        linearSearchSuccess: true,
        linearSearchResult: action.payload,
      };
    case GET_OD_SEARCH_ERROR:
      return {
        ...state,
        odSearchLoading: false,
        odSearchError: true,
        odSearchSuccess: false,
      };
    case GET_LINEAR_SEARCH_ERROR:
      return {
        ...state,
        linearSearchLoading: false,
        linearSearchError: true,
        linearSearchSuccess: false,
      };
    case SHOULD_DEFAULT_TAB:
      return {
        ...state,
        shouldDefaultTab: action.payload,
      };
    case UPDATE_INVALID_SEARCH:
      return {
        ...state,
        isSearchInvalid: action.payload,
      };
    case UPDATE_INVALID_SEARCH_INPUT: {
      return {
        ...state,
        isSearchInputInvalid: action.payload,
      };
    }
    default:
      return state;
  }
};
export default listingReducer;

// Actions
export const getListing: ActionCreator<
  ThunkAction<void, IListingState, void, IListingRequest | IListingError | IListingSuccess>
> =
  (listType: string, pageNumber = 1) =>
  async (dispatch) => {
    dispatch({ type: GET_LISTING });
    try {
      const payload = await fetchListing(listType, pageNumber);
      dispatch({ type: GET_LISTING_SUCCESS, payload });
    } catch (e) {
      dispatch({ type: GET_LISTING_ERROR });
    }
  };

// Actions
export const getEBListing: ActionCreator<
  ThunkAction<void, IListingState, void, IEBListingRequest | IEBListingError | IEBListingSuccess>
> = (listType: string, movieTypeFilter: string) => async (dispatch) => {
  dispatch({ type: GET_EB_LISTING });
  try {
    const payload = await fetchEarlyBirdListing(listType, movieTypeFilter);
    dispatch({ type: GET_EB_LISTING_SUCCESS, payload });
  } catch (e) {
    dispatch({ type: GET_EB_LISTING_ERROR });
  }
};

export const getSwimlaneListing: ActionCreator<
  ThunkAction<void, IListingState, void, IListingRequest | IListingError | IListingSuccess>
> =
  (route: string, pageNumber = 1) =>
  async (dispatch) => {
    dispatch({ type: GET_LISTING });
    try {
      const payload = await fetchListing(route, pageNumber, true);
      dispatch({ type: GET_LISTING_SUCCESS, payload });
    } catch (e) {
      dispatch({ type: GET_LISTING_ERROR });
    }
  };

export const getNextListing: ActionCreator<
  ThunkAction<void, IListingState, void, INextListingRequest | INextListingError | INextListingSuccess>
> =
  (listType: string, pageNumber: number, swimlane = false) =>
  async (dispatch) => {
    dispatch({ type: GET_NEXT_LISTING });
    try {
      const payload = await fetchListing(listType, pageNumber, swimlane);
      dispatch({ type: GET_NEXT_LISTING_SUCCESS, payload });
    } catch (e) {
      dispatch({
        type: GET_NEXT_LISTING_SUCCESS,
        payload: {
          items: [],
          pagination: null,
        },
      });
    }
  };

export const getChannelListing: ActionCreator<
  ThunkAction<void, IListingState, void, IChannelListingRequest | IChannelListingError | IChannelListingSuccess>
> = () => async (dispatch) => {
  dispatch({ type: GET_CHANNEL_LISTING });
  try {
    const apiResponse = await axios.get<IAPIResponse<IChannelListing[]>>(settings.contentHub.channel);
    dispatch({ type: GET_CHANNEL_LISTING_SUCCESS, payload: apiResponse.data.response });
  } catch (e) {
    dispatch({ type: GET_CHANNEL_LISTING_ERROR });
  }
};

export const updateListType: ActionCreator<IUpdateListType> = (listType: IListType) => ({
  type: UPDATE_LIST_TYPE,
  payload: listType,
});

export const updateSwimlane: ActionCreator<IUpdateSwimlane> = (swimlane: ISwimlane) => ({
  type: UPDATE_SWIMLANE,
  payload: swimlane,
});

export const updateFilters: ActionCreator<IUpdateFilters> = (filters: IFilter[]) => ({
  type: UPDATE_FILTERS,
  payload: filters,
});

export const toggleFilter: ActionCreator<IToggleFilter> = (state: boolean) => ({
  type: TOGGLE_FILTER,
  payload: state,
});

async function fetchListing(listType: string, pageNumber = 1, swimlane?: boolean) {
  try {
    const path = interpolate(swimlane ? settings.contentHub.swimlaneListing : settings.contentHub.listing, {
      listType,
      pageNumber: pageNumber.toString(),
    });
    const apiResponse = await axios.get<IAPIResponse<IListing>>(path);
    return apiResponse.data.response;
  } catch (e) {
    throw e;
  }
}

async function fetchEarlyBirdListing(listType: string, movieType: string, swimlane?: boolean) {
  try {
    const path = interpolate(swimlane ? settings.contentHub.swimlaneListing : settings.contentHub.earlyBirdListing, {
      listType,
      movieType,
    });
    const apiResponse = await axios.get<IAPIResponse<IEBItem[]>>(path);
    return apiResponse.data.response;
  } catch (e) {
    throw e;
  }
}

// Filtered listing for First, Best, Movies, Shows, Kids
export const getFilteredContentListing: ActionCreator<
  ThunkAction<void, IListingState, void, IListingRequest | IListingError | IListingSuccess>
> =
  (listType: string, filter: string, pageNumber = 1) =>
  async (dispatch) => {
    dispatch({ type: GET_LISTING });
    try {
      const payload = await fetchFilteredContentListing(listType, filter, pageNumber);
      dispatch({ type: GET_LISTING_SUCCESS, payload });
    } catch (e) {
      dispatch({ type: GET_LISTING_ERROR });
    }
  };

// Load next page for First, Best, Movies, Shows, Kids
export const getNextFilteredContentListing: ActionCreator<
  ThunkAction<void, IListingState, void, INextListingRequest | INextListingError | INextListingSuccess>
> = (listType: string, filter: string, pageNumber: number) => async (dispatch) => {
  dispatch({ type: GET_NEXT_LISTING });
  try {
    const payload = await fetchFilteredContentListing(listType, filter, pageNumber);
    dispatch({ type: GET_NEXT_LISTING_SUCCESS, payload });
  } catch (e) {
    dispatch({
      type: GET_NEXT_LISTING_SUCCESS,
      payload: {
        items: [],
        pagination: null,
      },
    });
  }
};

// API call to fetch curated filtered content for First, Best, Movies, Shows, Kids
async function fetchFilteredContentListing(listType: string, filter: string, pageNumber = 1) {
  try {
    const path = interpolate(settings.contentHub.filteredContentListing, {
      listType,
      filter,
      pageNumber: pageNumber.toString(),
    });
    const apiResponse = await axios.get<IAPIResponse<IListing>>(path);
    return apiResponse.data.response;
  } catch (e) {
    throw e;
  }
}

// API call to get search results
async function fetchSearchResult(type: string, value: string) {
  try {
    const path = interpolate(settings.contentHub.search, {
      type,
      value,
    });
    const apiResponse = await axios.get<IAPIResponse<IItem[]>>(path);
    return apiResponse.data.response;
  } catch (e) {
    throw e;
  }
}

export const getODSearchResult: ActionCreator<
  ThunkAction<void, IListingState, void, ISearchRequest | IODSearchError | IODSearchSuccess>
> = (value: string) => async (dispatch) => {
  dispatch({ type: GET_SEARCH });
  // OD Search
  try {
    const payload = await fetchSearchResult("od", value);
    dispatch({ type: GET_OD_SEARCH_SUCCESS, payload });
  } catch (e) {
    dispatch({ type: GET_OD_SEARCH_ERROR });
  }
};

export const getLinearSearchResult: ActionCreator<
  ThunkAction<void, IListingState, void, ISearchRequest | ILinearSearchError | ILinearSearchSuccess>
> = (value: string) => async (dispatch) => {
  dispatch({ type: GET_SEARCH });
  // Linear Search
  try {
    const payload = await fetchSearchResult("linear", value);
    dispatch({ type: GET_LINEAR_SEARCH_SUCCESS, payload });
  } catch (e) {
    dispatch({ type: GET_LINEAR_SEARCH_ERROR });
  }
};

export const updateCurrentSearchTab: ActionCreator<IUpdateSearchTab> = (tabIndex: number) => ({
  type: UPDATE_SEARCH_TAB,
  payload: tabIndex,
});

// If users enter less than 3 characters for search.
export const updateIsSearchInvalid: ActionCreator<IUpdateInvalidSearch> = (value: boolean) => ({
  type: UPDATE_INVALID_SEARCH,
  payload: value,
});

// If any script or html is entered for search.
export const updateIsSearchInputInvalid: ActionCreator<IUpdateInvalidSearchInput> = (value: boolean) => ({
  type: UPDATE_INVALID_SEARCH_INPUT,
  payload: value,
});

// Upon first entry to search results page, this handles whether search should default to a tab with valid results.
export const updateShouldDefaultTab: ActionCreator<IShouldDefaultTab> = (value: boolean) => ({
  type: SHOULD_DEFAULT_TAB,
  payload: value,
});
