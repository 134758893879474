import type { IDictionary, FILTERTYPE } from "@ducks/types";

export interface IChannelDefaultImages {
  [key: string]: string;
}

export interface IAppConfig {
  /**
   * Routing pattern for each page.
   */
  routes: IAppRoutes;
  /**
   * Symbol for breadcrumbs separator.
   */
  breadcrumbsSeparator: string;
  /**
   * Category that will be shown if there are no filters found from Details API response and the list type state is `null`.
   */
  breadcrumbsDefaultCategory: string;
  /**
   * Mapping used to map filter values from Details API to a specific list type.
   */
  breadcrumbsCategoryMap: IDictionary<string>;
  /**
   * Timezone offset to always show schedule time in a specific timezone.
   */
  showtimesTimezone: number;
}

export interface IAppRoutes {
  listing: string;
  details: string;
  channel: string;
  howToGuide: string;
}

export enum LISTINGLAYOUT {
  card = "card",
  poster = "poster",
  channel = "channel",
}

export interface IListingConfig {
  /**
   * Flag to toggle the visibility of the hero banner section.
   */
  showBanner: boolean;
  /**
   * Dailymotion Config for banner's trailer player
   */
  bannerDMConfig: IDMPlayerConfig;
  /**
   * Autoplay duration for hero banner in milliseconds.
   */
  bannerAutoplayDuration: number;
  /**
   *
   */
  defaultBannerBackground: string;
  /**
   * Determines which listing to load in "/" page.
   */
  defaultListType: string;
  /**
   * An array of list type information. The sequence determines the order in sub-navigation tabs.
   */
  listTypes: IListType[];
  /**
   * Number of columns to display in card listing layout.
   */
  cardColumns: IListingColumnNumber;
  /**
   * Number of columns to display in poster listing layout.
   */
  posterColumns: IListingColumnNumber;
  /**
   * Number of columns to display in channel listing layout.
   */
  channelColumns: IListingColumnNumber;
  /**
   * Number of schedule to show in each channel listing.
   */
  maxChannelSchedule: number;
  /**
   * List of channel categories to be displayed under hompeage banner
   */
  channelCategories: IChannelCategory[];
  /**
   * Number of columns to display in swimlane slider for desktop
   */
  swimlaneColumns: ISwimlaneColumns;

  swiperConfig: ISwiper;

  /**
   * string used for Sports scoreboard See all Cta .
   */
  sportsSeeAllCtaText?: string;
  /**
   * URL used for Sports scoreboard See all Cta .
   */
  sportsSeeAllCtaLink?: string;
}

export interface ISwiper {
  breakpoints: {
    lgDevice: Record<string, unknown>;
    smDevice: Record<string, unknown>;
  };
}
export interface IChannelCategory {
  title: string;
  url: string;
  image: string;
  default: boolean;
}

export interface ISwimlaneColumns {
  card: number;
  poster: number;
}

export interface IListType {
  /**
   * Value used to call API and used as the key to indicate a specific list type throughout the config.
   * This value should not change and FE/BE have to be aligned with it.
   */
  id: string;
  /**
   * URL used in this list type.
   */
  url: string;
  /**
   * Layout used for this list type.
   */
  layout: LISTINGLAYOUT;
  /**
   * Default page to load when url is root
   */
  default: boolean;
  /**
   * Flag to indicate whether this list type is the landing page (swimlanes)
   */
  isLanding: boolean;
  /**
   * Flag to indicate whether this list type is the channel listing
   */
  isChannel: boolean;
  /**
   * Banner ID will be used to call banners API.
   */
  bannerId: string;
  /**
   * Flag to toggle the visibility of "More" button for pagination in this listing.
   */
  hasPagination: boolean;
  /**
   * (Optional) Default hero section background color to be applied when there are no fallback colors found from banner API.
   */
  bannerBackground?: string;

  hasLeagueScore?: boolean;
}

export interface IListingColumnNumber {
  mobile: number;
  tablet: number;
  desktop: number;
}

export interface IDMPlayerConfig {
  uiStartScreenInfo: boolean;
  uiLogo: boolean;
  stopOnUnmount: boolean;
  height: string;
  width: string;
  url: string;
}

export interface IDetailsConfig {
  /**
   * D
   */
  icons: IDetailsIcon;
  /**
   * Order of programme info keys in desktop. Outer array represents its column, inner array represents its row.
   */
  desktopInfo: string[][];
  /**
   * Order of programme info keys in mobile.
   */
  mobileInfo: string[];
  /**
   * Maximum cast number to be displayed.
   */
  maxCastNumber: number;
  /**
   * How-To Guide URL.
   */
  howToGuideLink: string;
  /**
   * WhatsApp icon.
   */
  whatsappIcon: string;
  /**
   * WhatsApp API's URL to send message.
   */
  whatsappUrl: string;
  /**
   * WhatsApp number.
   */
  whatsappPhoneNumber: string;
  /**
   * Maximum number of rows to display in linear details' showtimes.
   */
  maxLinearShowtimes: number;
  /**
   * Maximum number of episodes to display in linear details' other episodes.
   */
  maxLinearEpisodes: number;
}

export interface IDetailsIcon {
  u: string;
  p13: string;
  "18": string;
  "21": string;
  hd: string;
  uhd: string;
  "3d": string;
  subtitle: string;
  multipleAudio: string;
  vod: string;
  astroGo: string;
}

export type FilterTypeMap<T> = {
  [key in keyof typeof FILTERTYPE]: T;
};

export interface IFilterSidebarConfig {
  groups: IDictionary<FILTERTYPE[]>;
}

export interface IHowToGuideConfig {
  showBanner: boolean;
  banner: string;
}

export interface ILanguages {
  en: ILanguage;
}

export interface ILanguage {
  CONTENT: string;
  MORE: string;
  GENERIC_ERROR_TITLE: string;
  GENERIC_ERROR_MESSAGE: string;
  PAGE_NOT_FOUND_TITLE: string;
  PAGE_NOT_FOUND_MESSAGE: string;
  NO_CHANNELS_FOUND: string;
  GO_TO_HOME: string;
  BACK_TO_CONTENT_HUB: string;
  CHANNEL_PREFIX: string;
  VIEW_ON_CHANNEL: string;
  listing: IListingLanguage;
  details: IDetailsLanguage;
  channels: IChannelsLanguage;
  filter: IFilterLanguage;
  howToGuide: IHowToGuideLanguage;
  filterMap: IDictionary<string>;
  languageMap: IDictionary<string>;
  channelMap: IDictionary<string>;
  purchaseMap: IDictionary<string>;
  CATEGORY: string;
}

export interface IListingLanguage {
  TITLE: string;
  WATCH_TRAILER: string;
  AVAILABLE_ON_DEMAND: string;
  LOADING: string;
  NO_SHOW_FOUND: string;
  NO_ITEMS_FOUND: string;
  HOMEPAGE: string;
  SEARCH: string;
  SEARCH_RESULT: string;
  NUMBER_OF_RESULTS_AMOUNT: string;
  NUMBER_OF_RESULTS_QUERY: string;
  INVALID_SEARCH: string;
  INVALID_SEARCH_INPUT: string;
  COMING_SOON: string;
  EARLY_BIRD_PROMO: string;
  COMING_TO_YOU: string;
  ALL_MOVIES: string;
  tabs: IDictionary<string>;
  headers: IDictionary<string>;
}

export interface IDetailsLanguage {
  OR: string;
  CH: string;
  NOW: string;
  SHOW_EXPIRED: string;
  DURATION: string;
  ON_DEMAND: string;
  ASTRO_GO: string;
  HOW_TO_GUIDE: string;
  WIFI_TO_CONNECT: string;
  WHATSAPP: string;
  PRICE: string;
  PRICE_FOR_DAYS: string;
  AFAB_PRICE_FOR_DAYS: string;
  PURCHASE_VIA_WHATSAPP: string;
  SHOWTIMES: string;
  TODAY: string;
  TOMORROW: string;
  ON_NOW: string;
  SHOWTIMES_NO_INFO: string;
  MORE_EPISODES: string;
  BACK_TO_CONTENT_HUB: string;
  attributes: IDictionary<string>;
  UPSELL_EXPLORE: string;
  CHOOSE_YOUR_PACK: string;
  EARLY_BIRD_PROMO: string;
  COMING_SOON: string;
  SHOWING_ON: string;
}

export interface IChannelsLanguage {
  ASTRO_GO_EXCLUSIVE: string;
  ON_NOW: string;
  NA: string;
  NO_INFORMATION_AVAILABLE: string;
  TODAY: string;
  ON_DEMAND_EXCLUSIVE: string;
  ON_DEMAND_CHANNELS: string;
}

export interface IFilterLanguage {
  CHANNEL_FILTER: string;
  FILTER: string;
  RESET: string;
  APPLY: string;
}

export interface IFilterGroup {
  header: string;
  filters: string[];
}

export interface IHowToGuideLanguage {
  TITLE: string;
  INSTRUCTION: string;
}

export interface IHowToGuide {
  title: string;
  content: string;
}

export interface IMetaConfig {
  titleTemplate: string;
  thumbnail: string;
  ogType: string;
  twitterCard: string;
  twitterSite: string;
  listing: IListingMeta;
  details: IDetailsMeta;
  channel: IChannelMeta;
  search: IListingCategoryMeta;
}

export interface IResizerConfig {
  url: string;
  config: {
    default: string;
    detail: string;
    card_portrait: string;
    card_landscape: string;
  };
}

export interface IListingMeta {
  categories: IDictionary<IListingCategoryMeta>;
  bannerAlt: string;
  productAlt: string;
}

export interface IListingCategoryMeta {
  title: string;
  description: string;
  keywords: string;
}

export interface IDetailsMeta {
  titleTemplate: string;
  title: string;
  channelDescription: string;
  castDescription: string;
  keywords: string;
  bannerAlt: string;
  productAlt: string;
}

export interface IChannelMeta {
  titleTemplate: string;
  title: string;
  description: string;
}

export interface IFilterConfig {
  filter: {
    groups: FilterTypeMap<IFilterGroup>;
    combinedLanguageMap: IDictionary<string[]>;
  };
  filterMap: IDictionary<string>;
}
