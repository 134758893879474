window.dataLayer = window.dataLayer || [];

export interface IGtmEvent {
  [key: string]: any;
}

export function push(event: IGtmEvent) {
  try {
    window?.dataLayer?.push(event);
  } catch (e) {
    console.error(e ? e : "Error: GTM container not loaded");
  }
}
