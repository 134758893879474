import { interpolate } from "acm-components";
import axios from "axios";
import settings from "@settings";
import { Action, Reducer } from "redux";
import { ThunkAction } from "redux-thunk";
import { ILeagueList, IResponseConfig, ResponseType1 } from "./type";

interface LeagueState {
  data: ILeagueList[];
  loading: boolean;
  error: boolean;
  success: boolean;
}

// Initial state
const initialState: LeagueState = {
  loading: true,
  error: false,
  success: false,
  data: [{ label: "", limit: 0, minusDate: 0, plusDate: 0, value: "", year: "" }],
};

// actions.ts

export const FETCH_LEAGUE_DATA_REQUEST = "leagueList/FETCH_LEAGUE_DATA_REQUEST";
export const FETCH_LEAGUE_DATA_SUCCESS = "leagueList/FETCH_LEAGUE_DATA_SUCCESS";
export const FETCH_LEAGUE_DATA_FAILURE = "leagueList/FETCH_LEAGUE_DATA_FAILURE";

interface FetchDataRequestAction {
  type: typeof FETCH_LEAGUE_DATA_REQUEST;
}

interface FetchDataSuccessAction {
  type: typeof FETCH_LEAGUE_DATA_SUCCESS;
  payload: IResponseConfig;
}

interface FetchDataFailureAction {
  type: typeof FETCH_LEAGUE_DATA_FAILURE;
  error: boolean;
}

export type DataActions = FetchDataRequestAction | FetchDataSuccessAction | FetchDataFailureAction;

export const fetchLeagueData = (): ThunkAction<void, LeagueState, null, Action<string>> => async (dispatch) => {
  dispatch({ type: FETCH_LEAGUE_DATA_REQUEST });
  try {
    const response = await axios.get(settings.contentHub.leagueSelect);
    const data: ResponseType1 = response.data?.response?.config[2];
    dispatch({ type: FETCH_LEAGUE_DATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_LEAGUE_DATA_FAILURE });
  }
};

const leagueDataReducer: Reducer = (state = initialState, action): LeagueState => {
  switch (action.type) {
    case FETCH_LEAGUE_DATA_REQUEST:
      return { ...state, loading: true };
    case FETCH_LEAGUE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: action.payload?.value.homeLanding.components[0].properties.leagueList,
      };
    case FETCH_LEAGUE_DATA_FAILURE:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};
export default leagueDataReducer;

// function to fetch selected dropdown id leaguelisting data
export async function fetchLeagueListing(leagueId: string, startDate: string, endDate: string) {
  try {
    const path = interpolate(settings.contentHub.leagueListing, {
      leagueId,
      startDate,
      endDate,
    });
    return await axios.get(path);
  } catch (e) {
    throw e;
  }
}
