import { NavEnvironment } from "acm-components";
import json from "settings.json";

interface ISettings {
  navEnv: NavEnvironment;
  config: {
    url: string;
    key: {
      app: string;
      listing: string;
      details: string;
      filterSidebar: string;
      howToGuide: string;
      whatsapp: string;
      language: string;
      meta: string;
      resizer: string;
      filterConfig: string;
      channelDefaultImages: string;
    };
    whatsonUrl: string;
    interactiveStudioBeacon: string;
    newRelic: string;
  };
  contentHub: {
    url: string;
    listing: string;
    details: string;
    banners: string;
    howToGuide: string;
    channel: string;
    channelDetails: string;
    linearDetail: string;
    epgDetail: string;
    swimlanes: string;
    swimlaneListing: string;
    filteredContentListing: string;
    search: string;
    afabDetails: string;
    earlyBirdListing: string;
    leagueSelect: string;
    leagueListing: string;
  };
}

const settings: ISettings = json as ISettings;

export default settings;
