interface OPTIONS {
  days?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}

export default class MyDate {
  /* The number of minutes offset from utc */
  private _utcOffset: number;
  private _date: Date;
  private _isUTC: boolean;

  private readonly utcDateRegex =
    /^([0-9]{4})-([01][0-9])-([0-2][0-9]|[3][01])[T ]([0-1][0-9]|[2][0-3]):([0-5][0-9]):([0-5][0-9])(.[0-9]{1,3})?(Z|[+-][0-9]{2}:[0-5][0-9])?$/;
  private readonly tzRegex = /(Z|[+-][0-9]{2}:[0-5][0-9])/;
  private readonly dateOnlyRegex = /^([0-9]{4})-([01][0-9])-([0-2][0-9]|[3][01])$/;
  private readonly months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  private readonly days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  //dates from AMS will be coverted to UTC even though it was set in GMT+8 format
  //frontend will still take the UTC date (i.e. 1 Sep 2020 to 31 Aug 2020). So, frontend needs to offset the time by 8 hours (8*60 minutes)
  constructor(param?: string | number) {
    this._utcOffset = 0;
    this._isUTC = false;

    if (typeof param === "string") {
      if (this.utcDateRegex.test(param)) {
        if (this.tzRegex.test(param)) this._date = new Date(param.replace(" ", "T"));
        else this._date = new Date(param.replace(" ", "T") + "Z");
      } else if (this.dateOnlyRegex.test(param)) this._date = new Date(param + "T00:00:00.000Z");
      else this._date = new Date(NaN);
    } else if (typeof param === "number") {
      this._date = new Date(param);
    } else this._date = new Date();
  }

  public static duration(duration: string | OPTIONS) {
    if (typeof duration === "string") {
      const timeRegex = /^([0-1][0-9]|[2][0-3]):([0-5][0-9]):([0-5][0-9])(.[0-9]{1,3})?$/;
      if (timeRegex.test(duration)) {
        const times = duration.split(":");
        const hoursInMS = parseInt(times[0]) * 60 * 60 * 1000;
        const minutesInMS = parseInt(times[1]) * 60 * 1000;
        const ms = parseFloat(times[2]) * 1000;
        return new MyDate().setTime(hoursInMS + minutesInMS + ms);
      } else return new MyDate(NaN);
    } else return new MyDate(MyDate.processOPTIONS(duration));
  }

  public get utc(): MyDate {
    if (this._isUTC) return this;

    const utc = new MyDate(this._date.getTime() - this._utcOffset * 60 * 1000);
    utc._isUTC = true;
    return utc;
  }

  public get utcOffset(): number {
    return this._utcOffset;
  }

  public setUTCOffset(v: number) {
    const utc = this._date.getTime() - this._utcOffset * 60 * 1000;
    this._utcOffset = v;
    this._date.setTime(utc + this._utcOffset * 60 * 1000);

    return this;
  }

  public isValid(): boolean {
    return !isNaN(this._date.getTime());
  }

  public getTime() {
    return this._date.getTime();
  }

  public setTime(ms: number) {
    this._date.setTime(ms);
    return this;
  }

  public asMinutes() {
    return this._date.getTime() / 1000 / 60;
  }

  public asSeconds() {
    return this._date.getTime() / 1000;
  }

  public getDate() {
    return this._date.getUTCDate();
  }

  public getMonth() {
    return this._date.getUTCMonth();
  }

  public getFullYear() {
    return this._date.getUTCFullYear();
  }

  public add(toAdd: MyDate | OPTIONS) {
    if (toAdd instanceof MyDate) {
      this._date.setTime(this._date.getTime() + toAdd.getTime());
    } else {
      const total = MyDate.processOPTIONS(toAdd);
      this._date.setTime(this._date.getTime() + total);
    }

    return this;
  }

  public isAfter(date: MyDate) {
    return this._date.getTime() > date.getTime();
  }

  public isBefore(date: MyDate) {
    return this._date.getTime() < date.getTime();
  }

  public isSame(date: MyDate, option?: string) {
    const isSameYear = this.getFullYear() === date.getFullYear();
    const isSameMonth = this.getMonth() === date.getMonth();
    const isSameDay = this.getDate() === date.getDate();
    switch (option) {
      case "day":
        return isSameYear && isSameMonth && isSameDay;
      default:
        return this._date.getTime() === date.getTime();
    }
  }

  public format(format: string) {
    const months = format.match(/Mo|M+/g);
    const daysOfMonth = format.match(/Do|D+/g);
    const daysOfWeek = format.match(/do|d+/g);
    const years = format.match(/Y+/g);
    const hours = format.match(/H+|h+/g);
    const minutes = format.match(/m+/g);
    const seconds = format.match(/s+|S+/g);
    const amPM = format.match(/A|a/g);

    amPM?.forEach((instance) => (format = format.replace(instance, `{{${instance}}}`)));
    months?.forEach((month) => (format = format.replace(month, `{{${month}}}`)));
    daysOfWeek?.forEach((day) => (format = format.replace(day, `{{${day}}}`)));

    daysOfMonth?.forEach((day) => (format = format.replace(day, this.getBigD(day))));
    years?.forEach((year) => (format = format.replace(year, this.getYearFormat(year))));
    hours?.forEach((hour) => (format = format.replace(hour, this.getHours(hour))));
    minutes?.forEach((minute) => (format = format.replace(minute, this.getMinutes(minute))));
    seconds?.forEach((second) => (format = format.replace(second, this.getSeconds(second))));

    months?.forEach((month) => (format = format.replace(`{{${month}}}`, this.getMonthFormat(month))));
    daysOfWeek?.forEach((day) => (format = format.replace(`{{${day}}}`, this.getSmallD(day))));
    amPM?.forEach((instance) => (format = format.replace(`{{${instance}}}`, this.getHours(instance))));

    return format;
  }

  private static processOPTIONS(option: OPTIONS) {
    let total = 0;
    if (option.days) total += option.days * 24 * 60 * 60 * 1000;
    if (option.minutes) total += option.minutes * 60 * 1000;
    if (option.seconds) total += option.seconds * 1000;
    if (option.milliseconds) total += option.milliseconds;
    return total;
  }

  private static padZero(num: number, digits: number) {
    const n = Math.abs(num);
    const zeros = Math.max(0, digits - Math.floor(n).toString().length);
    const zeroString = Math.pow(10, zeros).toString().substr(1);
    return zeroString + n;
  }

  private getMonthFormat(format: string) {
    const month = this._date.getUTCMonth() + 1;
    switch (format) {
      case "M":
        return month.toString();
      case "Mo":
        switch (month) {
          case 1:
            return month + "st";
          case 2:
            return month + "nd";
          case 3:
            return month + "rd";
          default:
            return month + "th";
        }
      case "MM":
        return MyDate.padZero(month, 2);
      case "MMM":
        return this.months[this._date.getUTCMonth()].substr(0, 3);
      case "MMMM":
        return this.months[this._date.getUTCMonth()];
      default:
        return format;
    }
  }

  private getBigD(format: string) {
    const date = this._date.getUTCDate();
    switch (format) {
      case "D":
        return date.toString();
      case "DD":
        return MyDate.padZero(date, 2);
      default:
        return format;
    }
  }

  private getSmallD(format: string) {
    switch (format) {
      case "ddd":
        return this.days[this._date.getUTCDay()].substr(0, 3);
      case "dddd":
        return this.days[this._date.getUTCDay()];
      default:
        return format;
    }
  }

  private getYearFormat(format: string) {
    switch (format) {
      case "YYYY":
        return this._date.getUTCFullYear().toString();
      default:
        return format;
    }
  }

  private getHours(format: string) {
    const hours = this._date.getUTCHours();
    const hour = hours > 12 ? hours - 12 : hours;
    const isPM = hours > 11;
    switch (format) {
      case "H":
        return hours.toString();
      case "HH":
        return MyDate.padZero(hours, 2);
      case "h":
        return hour.toString();
      case "hh":
        return MyDate.padZero(hour, 2);
      case "A":
        return isPM ? "PM" : "AM";
      case "a":
        return isPM ? "pm" : "am";
      default:
        return format;
    }
  }

  private getMinutes(format: string) {
    const minutes = this._date.getUTCMinutes();
    switch (format) {
      case "m":
        return minutes.toString();
      case "mm":
        return MyDate.padZero(minutes, 2);
      default:
        return format;
    }
  }

  private getSeconds(format: string) {
    const seconds = this._date.getUTCSeconds();
    const ms = this._date.getUTCMilliseconds();
    switch (format) {
      case "s":
        return seconds.toString();
      case "ss":
        return MyDate.padZero(seconds, 2);
      case "S":
      case "SS":
      case "SSS":
        return MyDate.padZero(ms, 3);
      default:
        return format;
    }
  }
}
